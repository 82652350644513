import React, { useEffect, useState } from 'react';
import './ReviewMarquee.css';
import { Box } from '@mui/material';


const reviews = [
  { id: 1, author: 'Maximilian', text: 'Tolles Unternehmen! Ich habe kürzlich eine digitale Vignette für Österreich gekauft und der gesamte Prozess war schnell und einfach.' },
  { id: 2, author: 'Sophia', text: 'Der Kundenservice war ausgezeichnet. Sie waren freundlich und hilfsbereit und beantworteten alle meine Fragen zu den digitalen Vignetten.' },
  { id: 3, author: 'Alexander', text: 'Ich bin so froh, dass ich keine physische Vignette mehr an meiner Windschutzscheibe anbringen muss. Die digitale Vignette ist super praktisch und spart mir Zeit.' },
  { id: 4, author: 'Emma', text: 'Die Website des Unternehmens ist benutzerfreundlich und intuitiv. Ich konnte meine digitale Vignette im Handumdrehen kaufen.' },
  { id: 5, author: 'Leon', text: 'Die Preise für die digitalen Vignetten waren fair und wettbewerbsfähig im Vergleich zu anderen Anbietern.' },
  { id: 6, author: 'Frederik', text: 'Meine digitale Vignette wurde sofort nach dem Kauf aktiviert und ich konnte sofort in Österreich unterwegs sein.' },
  { id: 7, author: 'Hanna', text: 'Ich schätze es, dass das Unternehmen eine Erinnerung schickt, wenn meine digitale Vignette bald abläuft. Es hilft mir, rechtzeitig zu verlängern.' },
  { id: 8, author: 'Nathanael', text: 'Ich schätze es, dass das Unternehmen eine Erinnerung schickt, wenn meine digitale Vignette bald abläuft. Es hilft mir, rechtzeitig zu verlängern.' },
  { id: 9, author: 'Hanna', text: 'Die digitalen Vignetten dieses Unternehmens werden problemlos an allen Mautstellen und Kontrollpunkten in Österreich akzeptiert.' },
  { id: 10, author: 'Elias', text: 'Es ist großartig, die Möglichkeit zu haben, meine digitale Vignette online ohne zusätzlichen Aufwand zu verlängern.' },
  { id: 11, author: 'Lina', text: 'Ich habe die digitale Vignette für mehrere Fahrzeuge gekauft und es war so praktisch, sie alle unter einem Konto zu verwalten.' },
  { id: 12, author: 'Ben', text: 'Das Unternehmen bietet klare Anweisungen zur Verwendung der digitalen Vignette und was bei eventuellen Problemen zu tun ist.' },
  { id: 13, author: 'Emilia', text: 'Ich schätze es, dass das Unternehmen verschiedene Zahlungsmethoden für den Kauf der digitalen Vignetten akzeptiert.' },
  { id: 14, author: 'Lukas', text: 'Die Kundendienstmitarbeiter sind gut informiert und reagieren schnell auf Fragen per E-Mail oder Telefon.' },
  { id: 15, author: 'Leonardo', text: 'Die Bestellung einer digitalen Vignette bei diesem Unternehmen hat mir im Vergleich zur Suche nach einem physischen Geschäft viel Zeit gespart.' },
  { id: 16, author: 'Henrietta', text: 'Das Unternehmen bietet regelmäßige Updates und Informationen zu neuen Vorschriften oder Änderungen in Bezug auf die digitalen Vignetten.' },
  { id: 17, author: 'Konstantin', text: 'Die Website des Unternehmens ist gut für die mobile Nutzung optimiert, sodass ich meine digitale Vignette problemlos unterwegs kaufen konnte.' },
  { id: 18, author: 'Gabriella', text: 'Ich fand es großartig, dass ich die digitale Vignette im Voraus kaufen konnte, bevor ich nach Österreich gereist bin. Es gab mir ein beruhigendes Gefühl.' },
  { id: 19, author: 'Valentina', text: 'Der Prozess des Übertragens einer digitalen Vignette auf ein anderes Fahrzeug war einfach und hat nur wenige Minuten gedauert.' },
  { id: 20, author: 'Wolf', text: 'Das Unternehmen bietet ein ausgezeichnetes Preis-Leistungs-Verhältnis für die digitale Vignette. Ich fühlte mich nicht abgezockt.' },
  { id: 21, author: 'Eleonora', text: 'Ich kann dieses Unternehmen wärmstens jedem empfehlen, der eine digitale Vignette für Österreich sucht. Der Service und die Qualität sind erstklassig!' },
];

function ReviewMarquee() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let interval = null;

    const startInterval = () => {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      }, 3000);
    };

    if (!isHovered) {
      startInterval();
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isHovered]);

  return (
    <div className="review-marquee row g-1 bg-light" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2 className='display-5' style={{ textAlign: 'center' }} >Was unsere Kunden sagen</h2>
      <div className='hgLBXr'></div>
       <div className="review-marquee-container">
        {reviews.map((review, index) => (
          <div
            key={review.id}
            className={` col-9 col-sm-6 col-md-6 col-lg-4 border card p-2`}
          >
           
            <p className="review-text">{review.text}</p>
            <p className="review-author">- {review.author}</p>
           </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewMarquee;
