import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import React from 'react';
import { Accordion } from 'react-bootstrap';

const FaqHomePage = () => {
  return (

    <div className="faq-page">   
      <Box className='container-lg py-5'>
        <div className="hero-banner">
          <h2>Häufig gestellte Fragen zur Vignette</h2>
          <p>Welche Arten von Vignetten gibt es? Wo und wie lange sind sie gültig? Wo kann ich sie erwerben? Gibt es eine Ersatzvignette? Im folgenden FAQ-Bereich haben wir die Antworten auf diese und andere häufig gestellte Fragen zur Vignette kompakt für Sie zusammengefasst.</p>
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Wie lange ist die Vignette gültig und wie viel kostet sie?</Accordion.Header>
            <Accordion.Body>

<p>Es gibt <strong>10-Tages</strong>, <strong>2-Monats</strong> und <strong>Jahresvignetten</strong>.</p>
<p><strong>Jahresvignette:</strong> Gültig für 14 Monate, nämlich vom 1. Dezember des Vorjahres bis zum 31. Januar des Folgejahres um Mitternacht.</p>
<p><strong>2-Monatsvignette:</strong> Gültig für zwei Monate. Beispiel: Gültig ab dem 10. Januar, Ende der Gültigkeit am 10. März um 24:00 Uhr.</p>
<p><strong>10-Tagesvignette:</strong> Gültig für zehn Tage. Beispiel für Gültigkeit am 10. Januar, Ende der Gültigkeit am 19. Januar um 24:00 Uhr.</p>
<table className="table table-striped d-none d-md-block">
            <thead>
                <tr>
                    <th>Vignette</th>
                    <th>Preis Asfinag</th>
                    <th>Vermittlungsgebühr</th>
                    <th>Gesamtpreis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>10-Tages vignette Österreich</td>
                    <td>€&nbsp;9.90</td>
                    <td>€&nbsp;10.00</td>
                    <td><strong>€&nbsp;19.90</strong></td>
                </tr>
                <tr>
                    <td>2-Monats vignette Österreich</td>
                    <td>€&nbsp;29,00</td>
                    <td>€&nbsp;10.00</td>
                    <td><strong>€&nbsp;39.00</strong></td>
                </tr>
                <tr>
                    <td>Jahres vignette Österreich</td>
                    <td>€&nbsp;96.40</td>
                    <td>€&nbsp;12.60</td>
                    <td><strong>€&nbsp;109.00</strong></td>
                </tr>
            </tbody>
        </table>

        <div className="d-block d-md-none">
    <ul className="list-group">
        <li className="list-group-item">
            <span> <span><strong>10-Tages Österreich vignette</strong></span><br></br></span>
            <span>Preis Asfinag: <span>€&nbsp;9.90</span><br></br></span>
            <span>Vermittlungsgebühr: <span>€&nbsp;10.00</span><br></br></span>
            <span>Gesamtpreis: <span><strong>€&nbsp;19.90</strong></span><br></br></span>
        </li>
        <li className="list-group-item">
            <span> <span><strong>2-Monats Österreich vignette</strong></span><br></br></span>
            <span>Preis Asfinag: <span>€&nbsp;29.00</span><br></br></span>
            <span>Vermittlungsgebühr: <span>€&nbsp;10.00</span><br></br></span>
            <span>Gesamtpreis: <span><strong>€&nbsp;39.00</strong></span><br></br></span>
        </li>
        <li className="list-group-item">
            <span> <span><strong>1-Jahres Österreich vignette</strong></span><br></br></span>
            <span>Preis Asfinag: <span>€&nbsp;96.40</span><br></br></span>
            <span>Vermittlungsgebühr: <span>€&nbsp;12.60</span><br></br></span>
            <span>Gesamtpreis: <span><strong>€&nbsp;109.00</strong></span><br></br></span>
        </li>
    </ul>
</div>
<p style={{ marginTop: '1rem' }}><mark><strong>WICHTIG:</strong> Eine nicht angebrachte oder entwertete Vignette (10-Tages oder 2-Monatsvignette) ist ungültig und kann zu Strafen von 300 € bis 3.000 € führen, sofern keine Ersatzmaut entrichtet wird. Stellen Sie sicher, dass Sie eine Bestätigungs-E-Mail von ASFINAG mit einem QR-Code erhalten haben.</mark></p>
</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
  <Accordion.Header>An welchen Straßen in Österreich besteht die Vignettenpflicht?</Accordion.Header>
  <Accordion.Body>
    <p>Grundsätzlich gilt die Mautpflicht auf allen Autobahnen und Schnellstraßen, die in Orange markiert sind, für Kraftfahrzeuge bis zu einem zulässigen Gesamtgewicht von 3,5 Tonnen (siehe Grafik). Sie benötigen keine Vignette für besonders kostenpflichtige Streckenabschnitte.</p>
    
    <p><strong>Die Ausnahmen gelten für folgende Strecken:</strong></p>
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <FontAwesomeIcon icon={faCheck} style={{ color: '#06c3a0' }}></FontAwesomeIcon>
      <p style={{ margin: '0' }}>die Mautstrecke A 1 West Autobahn zwischen der Landesgrenze am Walserberg und dem Knoten Salzburg-Nord</p>
    </Box>
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <FontAwesomeIcon icon={faCheck} style={{ color: '#06c3a0' }}></FontAwesomeIcon>
      <p style={{ margin: '0' }}>die Mautstrecke A 12 Inntal Autobahn zwischen der Landesgrenze bei Kufstein und dem Knoten Kufstein-Süd</p>
    </Box>
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <FontAwesomeIcon icon={faCheck} style={{ color: '#06c3a0' }}></FontAwesomeIcon>
      <p style={{ margin: '0' }}>die Mautstrecke A 14 Rheintal/Walgau Autobahn zwischen der Landesgrenze bei Hörbranz und dem Knoten Hohenems</p>
    </Box>
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <FontAwesomeIcon icon={faCheck} style={{ color: '#06c3a0' }}></FontAwesomeIcon>
      <p style={{ margin: '0' }}>die Mautstrecke A 26 Linzer Autobahn (noch im Bau)</p>
    </Box>
    <img src={'../images/asfinag_strecken_202111_streckenmaut_f-mautordnung_d.webp'} className=' ' style={{ maxWidth: '700px', width: '100%', position: 'relative' }} />
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="2">
  <Accordion.Header>Brauche ich für einen Anhänger/Wohnwagen/Beiwagen für ein Motorrad eine zusätzliche Vignette?</Accordion.Header>
  <Accordion.Body>
    <p>Nein. Für Anhänger, Wohnwagen, Beiwagen für Motorräder und ähnliche Fahrzeuge ist keine zusätzliche Vignette erforderlich.</p>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="3">
  <Accordion.Header>Welche Vignette benötige ich für ein Trike, einen Can-Am Spider oder ein Quad?</Accordion.Header>
  <Accordion.Body>
    <p>Gemäß der Mautordnung (§ 10 Abs. 3) gelten dreirädrige Kraftfahrzeuge wie <strong>Trikes und Can-Am Spyder</strong> als einspurige Fahrzeuge. Für diese Fahrzeuge reicht die Vignette für einspurige Fahrzeuge (Motorradvignette) aus.</p>
    <p><strong>Quads</strong> (auch wenn sie als Motorräder zugelassen sind) gelten als mehrspurige Fahrzeuge - unabhängig von der Spurbreite und unabhängig von der Führerscheinklasse - und benötigen eine Pkw-Vignette.</p>
    <p>Falls das Fahrzeug ohne Windschutzscheibe zugelassen ist, müssen Sie die Vignette nicht aufkleben. Stattdessen tragen Sie die Vignette bei sich und zeigen sie im Rahmen einer Kontrolle vor. Alternativ können Sie eine geeignete digitale Vignette in unserem Mautshop erwerben.</p>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="4">
  <Accordion.Header>Kann ich jederzeit zwischen der Klebevignette und der digitalen Vignette wechseln?</Accordion.Header>
  <Accordion.Body>
    <p>Ein Wechsel ist während der Gültigkeitsdauer der Vignette nicht möglich. Wenn Sie eine Jahresvignette gekauft haben, können Sie während der Gültigkeitsdauer (z.B. 1. Dezember 2022 bis 31. Januar 2024) nicht auf eine digitale Vignette wechseln. Der umgekehrte Wechsel ist ebenfalls nicht möglich. Für das folgende Vignettenjahr können Sie jedoch entscheiden, welche Vignettenvariante Sie wählen möchten.</p>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="5">
  <Accordion.Header>Wie wird die Vignette kontrolliert?</Accordion.Header>
  <Accordion.Body>
    <p><strong>ASFINAG überprüft</strong> stichprobenartig mithilfe unserer automatischen Vignettenkontrollkameras. Es gibt bis zu zwanzig automatische Vignettenkontrollkameras für über 2.200 Kilometer Autobahnen und Schnellstraßen. Diese Kameras sind mobil und wechseln ständig ihren Standort.</p>
    <p>Die Kontrollsysteme speichern nur Daten von Fahrzeugen, die keine gültige Klebevignette oder digitale Vignette haben. Diese Daten dienen ausschließlich als Nachweis von Mautvergehen und zur Bearbeitung von Ersatzmautansprüchen. Alle anderen Daten werden weder gespeichert noch in irgendeiner Form zur Verfügung gestellt.</p>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="6">
  <Accordion.Header>Das Vignette ist digital, aber was erhalte ich als Nachweis?</Accordion.Header>
  <Accordion.Body>
    <p>Als Nachweis für Ihren Kauf einer digitalen Vignette erhalten Sie einen elektronischen Kaufbeleg. Dieser Kaufbeleg wird Ihnen per E-Mail zugesandt, nachdem Sie die digitale Vignette erworben haben. Der elektronische Kaufbeleg enthält alle relevanten Informationen wie das Kennzeichen Ihres Fahrzeugs, die Gültigkeitsdauer der Vignette und weitere Details zu Ihrem Kauf. Sie können diesen Kaufbeleg auf Ihrem mobilen Gerät speichern oder ausdrucken und als Nachweis für die Gültigkeit der Vignette mitführen. Der elektronische Kaufbeleg ersetzt das früher auf der Windschutzscheibe anzubringende physische Vignette. Er bietet Ihnen den Komfort einer digitalen Lösung, ohne dass Sie ein greifbares Vignette benötigen.</p>
  </Accordion.Body>
</Accordion.Item>
        </Accordion>
      </Box>
    </div>
  );
};


export async function getServerSideProps() {
  return {
    props: {}, 
  };
}

export default FaqHomePage;
