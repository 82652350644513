import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51N9VO3BryLzMACBzMvoE3tc9de4HRkyry8j9CQmiA4LUs2AgrrcTmGr5VYhps2xkGgPGfY0tsTxB9hGgjixXmqRK00jU1teAJv');

function App() {
  const [products, setProducts] = useState([
    { id: 1, name: 'Product 1', price: 10 },
    { id: 2, name: 'Product 2', price: 20 },
    { id: 3, name: 'Product 3', price: 30 },
  ]);

  const handleCheckout = async (price) => {
    const stripe = await stripePromise;

    // Create a new Checkout Session
    const response = await fetch('/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ price }),
    });

    const session = await response.json();

    // Redirect to the Checkout page
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error('Error redirecting to Checkout:', result.error);
    }
  };

  return (
    <div>
      <h2>Products</h2>
      {products.map((product) => (
        <div key={product.id}>
          <h3>{product.name}</h3>
          <p>Price: {product.price}</p>
          <button onClick={() => handleCheckout(product.price)}>
            Buy Now
          </button>
        </div>
      ))}
    </div>
  );
}

export default App;
