import React from 'react';


const BackLinksPage = () => {
  return (
    <div>




<div id="fahrzeug" className='rounded-3 bg-light border' style={{ marginBottom: "0", marginTop: "80px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <h1 className='display-5 p-primary' style={{ textAlign: 'center' }}>Backlinks</h1>
 
              <div>

                  <a href="https://reisgids.reiskiezer.be">Reisgids</a>


              </div>
          </div>

      </div>
  );
};

export default BackLinksPage;
