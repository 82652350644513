import React, { useState } from 'react';
import { Navbar, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

  

function CustomNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Navbar color="light" light expand="lg">
        <div className="container mobile-wrapper">
          <NavbarToggler onClick={toggleNavbar}>
            {isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </NavbarToggler>
          <a className="navbar-brand" href="/">
            <img
              src="/images/DM.svg"
              alt=""
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
           </a>
         
<div className='d-none d-lg-block' style={{ marginLeft: 'auto' }}>
  
          <Nav className="ml-auto" style={{ gap: '15px' }} navbar>
          <NavItem>
            <NavLink href="/about-us" className="nav-link">Über uns</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/privacy-policy" className="nav-link">Datenschutz</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/terms-and-conditions" className="nav-link">Geschäftsbedingungen</NavLink>
          </NavItem>          
         
          <NavItem>
            <NavLink href="/faq" className="nav-link">FAQ</NavLink>
          </NavItem>     
               
          <NavItem>
            <NavLink href="/streckenmaut" className="nav-link">Digitale streckenmaut</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/vignette-oesterreich?selectedVehicle=PKW" className="nav-link">Digitale vignette</NavLink>
          </NavItem>          

          <NavItem>
            <NavLink href="/vignette-oesterreich?selectedVehicle=PKW" className="btn btn-primary btn-lg lightfont" style={{ color: '#fff' }}>Vignette kaufen</NavLink>
          </NavItem>
        </Nav>
        </div>
        </div>
      </Navbar>
      <Collapse isOpen={isOpen} navbar className={isOpen ? 'collapsed-menu show p-4' : 'collapsed-menu p-4'} style={{ background: '#313131' }}>
        <Nav className="ml-auto" style={{ gap: '15px' }} navbar>
        <NavItem>
            <NavLink href="/about-us" className="nav-link text-white">Über uns</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/privacy-policy" className="nav-link text-white">Datenschutz</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/terms-and-conditions" className="nav-link text-white">Geschäftsbedingungen</NavLink>
          </NavItem>          
          
        <NavItem>
            <NavLink href="/faq" className="nav-link" style={{ color: '#fff' }}>FAQ</NavLink>
          </NavItem>    
           
          <NavItem>
            <NavLink href="/streckenmaut" className="nav-link" style={{ color: '#fff' }}>Digitale streckenmaut</NavLink>
          </NavItem>          
          <NavItem>
            <NavLink href="/vignette-oesterreich?selectedVehicle=PKW" className="nav-link" style={{ color: '#fff' }}>Digitale vignette</NavLink>
          </NavItem>    
          <NavItem>
            <NavLink href="/vignette-oesterreich?selectedVehicle=PKW" className="btn btn-primary btn-lg">Vignette kaufen</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </div>
  );
}

export default CustomNavbar;
