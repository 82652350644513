 
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
 
const CancelPage = () => {
  return (
    <div className="faq-page">
            <Helmet>
 
 <meta name="robots" content="noindex,follow" />

</Helmet>
      <Box className='container-lg py-5'>

      <div className="hero-banner">
      <h1>Zahlung abgebrochen</h1>
          <p><mark><strong>Die Zahlung für Ihre Bestellung wurde abgebrochen.</strong></mark></p>
          <p>Wir verstehen, dass es möglicherweise ein Problem bei der Verarbeitung Ihrer Zahlung gegeben hat. Wir entschuldigen uns für die Unannehmlichkeiten.</p>
          <p>Wenn Sie weiterhin Interesse daran haben, Ihre Bestellung aufzugeben, empfehlen wir Ihnen, es erneut zu versuchen oder eine andere Zahlungsmethode auszuwählen.</p>
          <p>Wenn Sie Fragen zur Zahlung haben oder Hilfe benötigen, zögern Sie nicht, uns zu kontaktieren. Unser Team steht Ihnen gerne zur Verfügung.</p>

              <div style={{ display: 'flex' }}>
              <a href="/vignette-oesterreich" className="btn btn-primary btn-lg lightfont" style={{ color: '#fff', zIndex: '1', display: 'flex', alignItems: 'center', gap: '5  px' }}>Vignette auswählen</a>
    </div>
              <div style={{ display: 'flex', marginTop: '1rem' }}>
              <a href="/streckenmaut" className="btn btn-primary btn-lg lightfont" style={{ color: '#fff', zIndex: '1', display: 'flex', alignItems: 'center', gap: '5  px' }}>Streckenmaut auswählen</a>
    </div>
    </div>

 
      </Box>
    </div>
  );
};

export async function getServerSideProps() {
  return {
    props: {}, 
  };
}

export default CancelPage;
