import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState('');
  const [orderId, setOrderId] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [checkoutSessionId, setCheckoutSessionId] = useState('');

  useEffect(() => {
    const fetchOrder = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        // Redirect to an error page or handle the error
        navigate('/error');
        return;
      }

      try {
        // Fetch the order details from the server
        const response = await fetch(`https://digitalmautapi.onrender.com/order/success?session_id={sessionId}`);
        const data = await response.json();
        setCustomerName(data.customerName);
        setOrderId(data.orderId);
        setAmount(data.amount);
        setCurrency(data.currency);
        setEmail(data.email);
        setDescription(data.description);
        setLicensePlate(data.licensePlate);
        setCountryCode(data.countryCode);
        setSelectedDate(data.selectedDate);
        setSelectedVehicle(data.selectedVehicle);
        setSelectedProductName(data.selectedProductName);
        setSelectedProductId(data.selectedProductId);
        setCheckoutSessionId(sessionId);
      } catch (error) {
        // Handle any errors
        navigate('/error');
      }
    };

    fetchOrder();
  }, [location.search, navigate]);

  return (
    <div>
<Box className='container-lg'>

<Box id="fahrzeug" className='rounded-3 bg-light border' style={{ marginBottom: "0", textAlign: 'left', marginTop: '20px',  position: 'relative', alignItems: "left", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>

   <h2 className='display-5' >Vielen Dank für Ihre Bestellung, {customerName}!</h2>
   <p>Herzlichen Dank! Wir haben Ihre Bestellung erhalten und befinden uns in der Bearbeitung.</p>
   <mark><strong>Wichtig:</strong> Gehen Sie nicht auf die autobahn, bevor Sie von uns eine E-Mail mit Ihrem Vignette Österreich QR-Code und Bestätigungsmail erhalten haben.</mark>
</Box>
</Box>

<Box className='container-lg'>

<Box id="fahrzeug" className='rounded-3 bg-light border' style={{ marginBottom: "0", marginTop: '20px',  position: 'relative', alignItems: "left", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>

   <h3 className='display-5' style={{ textAlign: 'left'}} >Ihre Bestellnummer</h3>
   <p className='text-muted' style={{ zIndex: '1', overflowWrap: 'anywhere' }}>{orderId}</p>

</Box>
</Box>


<Box className='container-lg'>

<Box id="fahrzeug" className='rounded-3 bg-light border' style={{ marginBottom: "20px", marginTop: '20px',  position: 'relative', alignItems: "left", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>

   <h3 className='display-5' style={{ textAlign: 'left'}} >Ihre Bestellübersicht</h3>


<Box className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ textAlign: 'left'}}>
<div>
<>
  <p><strong>Fahrzeug:</strong></p>
  <p className='Geldig-tekst'>{selectedVehicle}</p>
</>

<div>
    <>
      <p><strong>Kennzeichen Fahrzeug:</strong></p> 
      <p className='Geldig-tekst uppercase notranslate '>{licensePlate}</p>
    </>

    <>
      <p><strong>Zulassungsstaat des Kennzeichens:</strong></p>
      <p className='Geldig-tekst uppercase'>{countryCode}</p>
    </>
</div>

<>
  <p><strong>Ausgewähltes Produkt:</strong></p>
  <p className='Geldig-tekst'>{selectedProductName}</p>
</>

<>
  <p><strong>Bedrag Produkt:</strong></p>
  <p className='Geldig-tekst'>{parseFloat(amount).toFixed(2)} EUR</p>
</>
<>
  <p><strong>Gültig ab:</strong></p>
  <p className='Geldig-tekst'>{selectedDate}</p>
</>
<>
  <p><strong>Sending QR code and confirmation to:</strong></p>
  <p className='Geldig-tekst'>{email}</p>
</>

 
</div>

</Box>


</Box>
</Box>
 

<Box className='container-lg'>

<Box id="fahrzeug" className='rounded-3 bg-light border' style={{ marginBottom: "20px", marginTop: '20px',  position: 'relative', alignItems: "left", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
<h3 className='display-5' style={{ textAlign: 'left'}} >Digitale Streckenmaut Österreich</h3>

<p>Wir möchten sicherstellen, dass Sie die Anforderungen und Regeln für das Fahren auf österreichischen Straßen mit einer gültigen Vignette einhalten. Aus diesem Grund senden wir Ihnen vor Ihrer Abreise eine E-Mail mit wichtigen Informationen. In dieser E-Mail finden Sie Ihren Vignette Österreich QR-Code und eine Bestätigung Ihres Kaufs.
</p>

<p>
Der QR-Code ist entscheidend, da er als Zahlungsnachweis dient und Ihnen Zugang zu den österreichischen Autobahnen gewährt. Stellen Sie sicher, dass Sie diese E-Mail zur Hand haben, bevor Sie Ihre Reise antreten.
</p>

<p>
Wir empfehlen Ihnen dringend, auf die E-Mail mit Ihrem Vignette Österreich QR-Code und der Bestätigungsmail zu warten, bevor Sie auf die Straße gehen. Dadurch können Sie problemlos reisen und mögliche Probleme oder Bußgelder vermeiden.

</p>

<p>
Wenn Sie noch Fragen haben, zögern Sie nicht, uns zu kontaktieren. Unser Team steht bereit, Ihnen zu helfen und Ihnen eine angenehme Reise zu ermöglichen.
</p>

 <h3 className='display-5' style={{ textAlign: 'left'}} ><mark>Achtung!</mark></h3>

<p>Bevor Sie nach Österreich reisen, ist es von wesentlicher Bedeutung sicherzustellen, dass Sie nicht nur eine Vignette haben, sondern auch über die <mark>Streckenmaut verfügen.</mark></p>

<div><a href="/streckenmaut" className="btn btn-primary btn-lg lightfont"> Zur digitale Trajecttol</a></div>
</Box>
</Box>

     </div>
  );
};

export default SuccessPage;
