 
import { Box } from '@material-ui/core';
import React from 'react';
 
 
const AboutUs = () => {
  
  return (
    <div>
        
  <Box id="fahrzeug" className='rounded-3 bg-light border' style={{ width: '100%', textAlign: 'center', marginBottom: "0", marginTop: "0px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
     <h1 className='display-5' style={{ width: '100%', textAlign: 'center', zIndex: '1'}} >Over ons</h1>
   </Box> 
  <Box id="fahrzeug" className='rounded-3 container-lg' style={{ width: '100%', maxWidth: '750px',  marginBottom: "0", marginTop: "0px", position: 'relative', marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <p>De Man Techniek<br/>
Jacob Schorerlaan 1 (geen bezoekadres)<br/>
2525 ZP Den Haag<br/>
Nederland</p>
<p>Verantwoordelijk voor de inhoud en redactie: Alain de Man<br/>
Directeur: Alain de Man<br/>
BTW-nummer: NL864820458B01<br/>
Kamer van Koophandel-nummer: 60532725 (Nederland)<br/>
Rechtsvorm: ZZP</p>
 
<h2 id="Contact" className="scroll-mt-6">Contact</h2>
 
<p>Tel.+31 (0) 6 10 27 78 89</p>
<p>E-Mail: <a href="mailto:info@<span className='notranslate'> Digitalmaut.nl </span>" target="_self">info@<span className='notranslate'> Digitalmaut.nl </span></a></p>
 
<h2 id="bankverbindung" className="scroll-mt-6">Bankgegevens</h2>

<p>IBAN: NL47 INGB 0006477319<br/>
BIC: INGBNL2A<br/>
Bank: ING Bank</p>
 

<p><em><span className='notranslate'>Digitalmaut.nl</span> is een <mark>bemiddelaar</mark> en vraagt namens u de gewenste Oostenrijkse vignetten aan bij de uitgever van Oostenrijk. Voor deze dienst brengt <span className='notranslate'>Digitalmaut.nl</span> bemiddelingskosten in rekening.</em></p>




    </Box> 
 

 
    </div>
  );
};
 

export default AboutUs;
