import { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Box } from "@mui/material";
import Product from './components/product';

 
import PaymentForm from './components/PaymentForm';
import CustomNavbar from "./components/Navbar";
 import ProductListMaut from "./components/ProductListMaut";
import Footer2 from "./components/Footer";
import FAQPage from "./pages/Base/Faq";
import Widerrufsrecht from "./pages/Base/Widerrufsrecht";
import Bestellen from "./pages/Base/Bestellung";
import HomePage from "./pages/Home";
 import SuccessPage from "./pages/Base/Success";
import CancelPage from "./pages/Base/Cancel";
import SchweizProducts from "./components/SchweizProducts";
import JahresVignette from "./pages/Blogs/JahresVignette";
import ProductList from "./components/ProductList";
import TankstelleVignette from "./pages/Blogs/TankstelleVignette";
import VignetteKaufen from "./pages/Blogs/VignetteKaufen";
import BackLinksPage from "./pages/Base/BackLinkPage";
 import BlogCard2 from "./components/BlogPostCard";
import Vignette1Blog from "./pages/Blogs/PreisVignette";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AvgPage from "./pages/AvgPage";
import Blogs1 from "./pages/Blogs/Blog1";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
 
   


function App() {
  const [notification, setNotification] = useState(null);

  return (
    <BrowserRouter>
    <CustomNavbar />
 
      <Box position="relative">
         <Routes>

        <Route path="/" element={<HomePage />} />
         <Route path="/products" element={<Product />} />
        <Route path="/streckenmaut" element={<ProductListMaut />} />
        <Route path="/vignette-schweiz" element={<SchweizProducts />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/Widerrufsrecht" element={<Widerrufsrecht />} />
        <Route path="/Bestellen" element={<Bestellen />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/blogs/jahres-vignette-oesterreich" element={<JahresVignette />} />
        <Route path="/blogs/Warum-Sie-digitale-oesterreich-Vignetten-kaufen-sollten" element={<TankstelleVignette />} />
        <Route path="/blogs/eine-Vignette-oesterreich-richtig-kaufen" element={<VignetteKaufen />} />
        <Route path="/vignette-oesterreich" element={<ProductList />} />
        <Route path="/backlinks" element={<BackLinksPage />} />
        <Route path="/payment" element={<PaymentForm />} />
         <Route path="/Blogs" element={<BlogCard2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
         <Route path="/terms-and-conditions" element={<AvgPage />} />
        <Route path="/blogs/Oesterreichg-vignette-preis" element={<Vignette1Blog />} />
        <Route path="/blogs/blogs1" element={<Blogs1 />} />
           <Route path="/about-us" element={<AboutUs />} />
         <Route path="/contact" element={<ContactUs />} />
   
 
        </Routes>
       </Box>
      <Footer2 />
    </BrowserRouter>
  );
}

export default App;
