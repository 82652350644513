import * as React from 'react';

export default function BlogCard2() {
  return (
    <div className="container-lg">
      <section className="text-left">
        <h4 className="mb-5"><strong>Neueste Beiträge</strong></h4>

        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card">
              <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
               <a href='/blogs/eine-Vignette-oesterreich-richtig-kaufen'><img src="/images/Österreich-1.jpg" className="img-fluid" /></a> 
                <a href="/blogs/eine-Vignette-oesterreich-richtig-kaufen">
                  <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                </a>
              </div>
              <div className="card-body">
                <h5 className="card-title">Geldstrafen und Ärger vermeiden: Wie man eine Vignette in Österreich richtig kauft</h5>
                <p className="card-text">
                Planen Sie eine Reise nach Österreich? Lassen Sie sich Ihre Reise nicht durch Bußgelder und unnötigen Ärger verderben. Egal, ob Sie die atemberaubenden Landschaften erkunden oder die...
                </p>
                <a href="/blogs/eine-Vignette-oesterreich-richtig-kaufen" className="btn btn-primary">Lesen</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card">
              <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
               <a href='/blogs/Warum-Sie-digitale-oesterreich-Vignetten-kaufen-sollten'><img src="/images/omv-fastlane_comv-aktiengesellschaft (3).jpg" className="img-fluid" /></a> 
                <a href="/blogs/Warum-Sie-digitale-oesterreich-Vignetten-kaufen-sollten">
                  <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                </a>
              </div>
              <div className="card-body">
                <h5 className="card-title">Geldstrafen und Ärger vermeiden: Wie man eine Vignette in Österreich richtig kauft</h5>
                <p className="card-text">
                Planen Sie eine Reise nach Österreich? Lassen Sie sich Ihre Reise nicht durch Bußgelder und unnötigen Ärger verderben. Egal, ob Sie die atemberaubenden Landschaften erkunden oder die...
                </p>
                <a href="/blogs/Warum-Sie-digitale-oesterreich-Vignetten-kaufen-sollten" className="btn btn-primary">Lesen</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-4">
            <div className="card">
              <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
               <a href='/blogs/eine-Vignette-oesterreich-richtig-kaufen'><img src="/images/Österreich-1.jpg" className="img-fluid" /></a> 
                <a href="/blogs/eine-Vignette-oesterreich-richtig-kaufen">
                  <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                </a>
              </div>
              <div className="card-body">
                <h5 className="card-title">Geldstrafen und Ärger vermeiden: Wie man eine Vignette in Österreich richtig kauft</h5>
                <p className="card-text">
                Planen Sie eine Reise nach Österreich? Lassen Sie sich Ihre Reise nicht durch Bußgelder und unnötigen Ärger verderben. Egal, ob Sie die atemberaubenden Landschaften erkunden oder die...
                </p>
                <a href="/blogs/eine-Vignette-oesterreich-richtig-kaufen" className="btn btn-primary">Lesen</a>
              </div>
            </div>
          </div>
 
 

         </div>
       </section>
       <section className='text-center'>
       <a href="/vignette-oesterreich" className="btn btn-primary btn-lg lightfont" style={{ color: '#fff', zIndex: '1', marginTop: '1rem', marginBottom: '1rem' }}>Naar alle artikelen</a>
       </section>
    </div>
  );
}
