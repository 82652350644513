import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Alert, AlertTitle, Button, Badge } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faLock,
  faCar,
  faMotorcycle,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { Toast } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const ReadOnlyInput = React.forwardRef(function ReadOnlyInput(
  { value, onClick },
  ref
) {
  return (
    <input
      className="customer-dater date rounded-lg bg-slate-50"
      type="text"
      readOnly
      onClick={onClick}
      value={value}
      ref={ref}
    />
  );
});

ReadOnlyInput.displayName = "ReadOnlyInput";

const stripePromise = loadStripe(
  "pk_live_51N9VO3BryLzMACBzMvoE3tc9de4HRkyry8j9CQmiA4LUs2AgrrcTmGr5VYhps2xkGgPGfY0tsTxB9hGgjixXmqRK00jU1teAJv"
);

function ProductList() {
  const [showToast, setShowToast] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [expressShipping, setExpressShipping] = useState(false);
  const [countryCode, setCountryCode] = useState("DE");
  const [licensePlate, setLicensePlate] = useState("");
  const [confirmLicensePlate, setConfirmLicensePlate] = useState("");
  const [licensePlateMatch, setLicensePlateMatch] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState("");
  const subtotal = selectedProduct ? selectedProduct.price : 0;
  const shippingCost = expressShipping ? 4.95 : 0;
  const totalPrice = subtotal + shippingCost;
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailMatch, setEmailMatch] = useState(true);
  const location = useLocation();

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    setEmailMatch(enteredEmail.toLowerCase() === confirmEmail.toLowerCase());
  };

  const handleConfirmEmailChange = (event) => {
    const enteredConfirmEmail = event.target.value;
    setConfirmEmail(enteredConfirmEmail);
    setEmailMatch(email.toLowerCase() === enteredConfirmEmail.toLowerCase());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount((prevCount) => prevCount + 1);
    }, 1000); // Refresh every second

    return () => {
      clearInterval(interval);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleVehicleSelection = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const filteredProducts = products.filter(
    (product) => product.vehicle === selectedVehicle
  );

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        "https://digitalmautapi.onrender.com/products"
      );
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Get the current URL
  var url = window.location.href;

  // Remove the URL parameters
  var cleanUrl = url.split("?")[0];

  // Update the browser URL without refreshing the page
  window.history.replaceState(null, null, cleanUrl);

  const handleCheckout = async () => {
    // Clear any previous errors
    setFormErrors([]);

    const stripe = await stripePromise;
    const name = document.getElementById("nameInput").value;
    const email = document.getElementById("emailInput").value;

    try {
      // Create a customer object in Stripe
      const customerResponse = await fetch(
        "https://digitalmautapi.onrender.com/create-customer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            licensePlate,
            countryCode,
            selectedDate,
            selectedVehicle,
            selectedProduct, // Include the selectedProduct in the request body
          }),
        }
      );

      const customerData = await customerResponse.json();

      // Calculate the total price
      let totalPrice = selectedProduct.price;
      if (expressShipping) {
        totalPrice += 4.95;
      }

      // Create a new Checkout Session
      const response = await fetch(
        "https://digitalmautapi.onrender.com/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: totalPrice * 100, // convert price to cents (Stripe expects the amount in the smallest currency unit)
            currency: "EUR", // replace with your desired currency code
            description: selectedProduct.name, // replace with your product description
            customerId: customerData.customerId, // pass the customer ID to associate the payment with the customer
          }),
        }
      );
      const session = await response.json();

      // Redirect to the Checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId, // use the correct property name from the server response
      });

      if (result.error) {
        console.error("Error redirecting to Checkout:", result.error);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product);
    setSelectedProductName(product.name);
  };

  const handleLicensePlateChange = (e) => {
    const enteredLicensePlate = e.target.value;
    setLicensePlate(e.target.value);
    setLicensePlateMatch(
      enteredLicensePlate.toLowerCase() === confirmLicensePlate.toLowerCase()
    );
    setInputError(false);
  };

  const handleConfirmLicensePlateChange = (e) => {
    const enteredConfirmLicensePlate = e.target.value;
    setConfirmLicensePlate(enteredConfirmLicensePlate);
    setLicensePlateMatch(
      licensePlate.toLowerCase() === enteredConfirmLicensePlate.toLowerCase()
    );
    setInputError(false);
  };

  const getCountryCodeColor = (countryCode) => {
    // Add image mappings for each country code
    switch (countryCode) {
      default:
        return "license-plate-default"; // Replace 'license-plate-default' with the CSS class for the default license plate image
    }
  };

  // useEffect to control the visibility of the toast
  useEffect(() => {
    setShowToast(formErrors.length > 0);
  }, [formErrors]);

  const handleFormSubmit = async () => {
    // Clear any previous form errors
    setFormErrors([]);

    // Perform the form validation
    const errors = [];

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      errors.push("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
    }

    if (!confirmEmail || email.toLowerCase() !== confirmEmail.toLowerCase()) {
      errors.push(
        "Die E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es erneut."
      );
    }

    if (!selectedProduct) {
      errors.push("Bitte wählen Sie eine Vignette aus.");
    }

    if (!licensePlate) {
      errors.push("Bitte geben Sie ein Kennzeichen ein.");
    }

    if (!confirmLicensePlate) {
      errors.push("Bitte bestätigen Sie das Kennzeichen.");
    }

    if (!countryCode) {
      errors.push("Bitte wählen Sie einen Ländercode für Ihr Kennzeichen aus.");
    }

    if (licensePlate.toLowerCase() !== confirmLicensePlate.toLowerCase()) {
      errors.push(
        "Die Kennzeichen stimmen nicht überein. Bitte versuchen Sie es erneut."
      );
      setLicensePlateMatch(false);
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    // Set loading state to true
    setIsLoading(true);

    // Simulate an asynchronous operation (e.g., an API call) using setTimeout
    setTimeout(() => {
      // Reset loading state
      setIsLoading(false);

      // Perform the actual form submission logic here
      handleCheckout();
    }, 1000); // Adjust the timeout duration as needed
  };

  const handleExpressShippingChange = (e) => {
    setExpressShipping(e.target.checked);
  };

  function formatDate(date) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("de-DE", options);
  }

  // Function to calculate the validity end date based on the selected date and product ID
  function calculateValidityEndDate(selectedDate, selectedProduct) {
    const endDate = new Date(selectedDate);

    if (selectedProduct.id === 1) {
      endDate.setDate(endDate.getDate() + 9); // Add 9 days for product ID 1
    } else if (selectedProduct.id === 2) {
      endDate.setMonth(endDate.getMonth() + 2); // Add 2 months for product ID 2
    } else if (selectedProduct.id === 3) {
      endDate.setTime(new Date("2025-01-31").getTime());
    } else if (selectedProduct.id === 4) {
      endDate.setDate(endDate.getDate() + 9);
    } else if (selectedProduct.id === 77) {
      endDate.setDate(endDate.getDate() + 19);
    } else if (selectedProduct.id === 78) {
      endDate.setDate(endDate.getDate() + 19);
    } else if (selectedProduct.id === 5) {
      endDate.setMonth(endDate.getMonth() + 2); // Add 2 months for product ID 2
    } else if (selectedProduct.id === 6) {
      endDate.setTime(new Date("2025-01-31").getTime());
    }

    return formatDate(endDate);
  }

  const CountryCode = [
    {
      value: "AT",
      label: "Österreich",
      licensePlate: "license-plate",
      licenseValue: "A",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen lt. Zulassungsschein bzw. Kennzeichentafel ein. Anstelle des Wappens verwenden Sie bitte einen Bindestrich.",
      "data-placeholdertext": "W-12345X",
    },
    {
      value: "DE",
      label: "Deutschland",
      licensePlate: "license-plate",
      licenseValue: "D",
      contentValue:
        'Anstelle der Plaketten verwenden Sie bitte einen Bindestrich. Leerstellen sind nicht einzugeben. Bei einem Elektrofahrzeug geben Sie bitte unbedingt auch das "E" am Ende des Kennzeichens ein.',
      "data-placeholdertext": "B-AB1234",
    },
    {
      value: "AL",
      label: "Albanien",
      licensePlate: "license-plate",
      licenseValue: "AL",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AA123BB",
    },
    {
      value: "AD",
      label: "Andorra",
      licensePlate: "license-plate",
      licenseValue: "AND",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "L0123",
    },
    {
      value: "BE",
      label: "Belgien",
      licensePlate: "license-plate",
      licenseValue: "B",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "1ABC123",
    },
    {
      value: "BA",
      label: "Bosnien und Herzegowina",
      licensePlate: "license-plate",
      licenseValue: "BIH",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "K12A456",
    },
    {
      value: "BG",
      label: "Bulgarien",
      licensePlate: "license-plate",
      licenseValue: "BG",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "CA1234XY",
    },
    {
      value: "DK",
      label: "Dänemark",
      licensePlate: "license-plate",
      licenseValue: "DK",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "DD123456",
    },
    {
      value: "EE",
      label: "Estland",
      licensePlate: "license-plate",
      licenseValue: "EST",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "432ABC",
    },
    {
      value: "FI",
      label: "Finnland",
      licensePlate: "license-plate",
      licenseValue: "FIN",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "MNO321",
    },
    {
      value: "FR",
      label: "Frankreich",
      licensePlate: "license-plate",
      licenseValue: "F",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AA123BB",
    },
    {
      value: "GR",
      label: "Griechenland",
      licensePlate: "license-plate",
      licenseValue: "GR",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "YXX2345",
    },
    {
      value: "GB",
      label: "Großbritannien",
      licensePlate: "license-plate",
      licenseValue: "GB",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "LA01ABC",
    },
    {
      value: "IE",
      label: "Irland",
      licensePlate: "license-plate",
      licenseValue: "IRL",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "17W12345",
    },
    {
      value: "IS",
      label: "Island",
      licensePlate: "license-plate",
      licenseValue: "IS",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AF123",
    },
    {
      value: "IT",
      label: "Italien",
      licensePlate: "license-plate",
      licenseValue: "I",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AA123CD",
    },
    {
      value: "XK",
      label: "Kosovo",
      licensePlate: "license-plate",
      licenseValue: "RKS",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "01123AB",
    },
    {
      value: "HR",
      label: "Kroatien",
      licensePlate: "license-plate",
      licenseValue: "HR",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "ZG123AB",
    },
    {
      value: "LT",
      label: "Litauen",
      licensePlate: "license-plate",
      licenseValue: "LT",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "BCA987",
    },
    {
      value: "LI",
      label: "Liechtenstein",
      licensePlate: "license-plate",
      licenseValue: "FL",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "FL12345",
    },
    {
      value: "LV",
      label: "Lettland",
      licensePlate: "license-plate",
      licenseValue: "LV",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "FM1234",
    },
    {
      value: "LU",
      label: "Luxemburg",
      licensePlate: "license-plate",
      licenseValue: "L",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AB1234",
    },
    {
      value: "MT",
      label: "Malta",
      licensePlate: "license-plate",
      licenseValue: "M",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "CDE123",
    },
    {
      value: "MD",
      label: "Moldawien",
      licensePlate: "license-plate",
      licenseValue: "MD",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "ABC012",
    },
    {
      value: "MC",
      label: "Monaco",
      licensePlate: "license-plate",
      licenseValue: "MC",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "7654",
    },
    {
      value: "ME",
      label: "Montenegro",
      licensePlate: "license-plate",
      licenseValue: "MNE",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "BDAA234",
    },
    {
      value: "NL",
      label: "Niederlande",
      licensePlate: "license-plate",
      licenseValue: "NL",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "CD34JK",
    },
    {
      value: "NMK",
      label: "Nordmazedonien",
      licensePlate: "license-plate",
      licenseValue: "NMK",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "SK2345AB",
    },
    {
      value: "NO",
      label: "Norwegen",
      licensePlate: "license-plate",
      licenseValue: "N",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "NY6789",
    },
    {
      value: "PL",
      label: "Polen",
      licensePlate: "license-plate",
      licenseValue: "PL",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "WP012AB",
    },
    {
      value: "PT",
      label: "Portugal",
      licensePlate: "license-plate",
      licenseValue: "P",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "1221AB",
    },
    {
      value: "RO",
      label: "Rumänien",
      licensePlate: "license-plate",
      licenseValue: "RO",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "BN11ABC",
    },
    {
      value: "RU",
      label: "Russland",
      licensePlate: "license-plate",
      licenseValue: "RUS",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "A123AA",
    },
    {
      value: "SM",
      label: "San Marino",
      licensePlate: "license-plate",
      licenseValue: "RSM",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "A5432",
    },
    {
      value: "SE",
      label: "Schweden",
      licensePlate: "license-plate",
      licenseValue: "S",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "DCB321",
    },
    {
      value: "CH",
      label: "Schweiz",
      licensePlate: "license-plate",
      licenseValue: "CH",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "ZH543210",
    },
    {
      value: "RS",
      label: "Serbien",
      licensePlate: "license-plate",
      licenseValue: "SRB",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "BG012AB",
    },
    {
      value: "SK",
      label: "Slowakei",
      licensePlate: "license-plate",
      licenseValue: "SK",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "TN345AB",
    },
    {
      value: "SI",
      label: "Slowenien",
      licensePlate: "license-plate",
      licenseValue: "SLO",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "LJAB123",
    },
    {
      value: "ES",
      label: "Spanien",
      licensePlate: "license-plate",
      licenseValue: "E",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "5678ABC",
    },
    {
      value: "CZ",
      label: "Tschechien",
      licensePlate: "license-plate",
      licenseValue: "CZ",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "1AB0123",
    },
    {
      value: "TR",
      label: "Türkei",
      licensePlate: "license-plate",
      licenseValue: "TR",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "34AB012",
    },
    {
      value: "UA",
      label: "Ukraine",
      licensePlate: "license-plate",
      licenseValue: "UA",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "AA1234AB",
    },
    {
      value: "HU",
      label: "Ungarn",
      licensePlate: "license-plate",
      licenseValue: "H",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "BCA123",
    },
    {
      value: "BY",
      label: "Weißrussland",
      licensePlate: "license-plate",
      licenseValue: "BY",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "1234AB5",
    },
    {
      value: "CY",
      label: "Zypern",
      licensePlate: "license-plate",
      licenseValue: "CY",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "ABC123",
    },
    {
      value: "XX",
      label: "Sonstige Länder",
      licensePlate: "license-plate",
      licenseValue: "XX",
      contentValue:
        "Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!",
      "data-placeholdertext": "ABC123",
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedVehicleParam = searchParams.get("selectedVehicle");
    setSelectedVehicle(selectedVehicleParam);
  }, [location.search]);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/",
            "@type": "CreativeWorkSeries",
            "name": "vignette-Österreich",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "bestRating": "5",
              "ratingCount": "3104"
            }
          }
          `}
        </script>

        <meta name="rating" content="General" />

        <title>Digitale Vignette Österreich - Fahrzeug auswählen</title>
        <meta
          name="keywords"
          content="Digitale Vignette, Vignette Österreich, Vignette bestellen, Digitale Vignette PKW, Digitale Vignette Motorrad, Gültigkeitszeitraum, Kennzeichen, QR-Code, Bestätigung, 10 Tages Vignette Österreich sofort gültig, 10 Tages Vignette Österreich digital, 10 Tages Vignette Österreich Kosten, 10 Tages Vignette Tschechien, Gültigkeit Jahresvignette Österreich, 10 Tages Vignette Österreich Tankstelle, 10 Tages Vignette Österreich 2023"
        />
        <meta
          name="description"
          content="Digitale Vignette Österreich - Bestellen Sie Ihre digitale Vignette für das gewünschte Fahrzeug. Wählen Sie Ihre gewünschte Vignette für PKW oder Motorrad. Wählen Sie den Gültigkeitszeitraum und geben Sie das Kennzeichen des Fahrzeugs ein. Geben Sie Ihre Kontaktdaten ein, um den QR-Code und die Bestätigung zu erhalten."
        />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Digitalmaut" />
        <meta name="publisher" content="Digital Maut" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <link
          rel="canonical"
          href="https://digitalmaut.de/vignette-oesterreich"
        />
      </Helmet>

      <Box
        className=""
        style={{
          marginBottom: "0",
          position: "relative",
          alignItems: "center",
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "3rem 1rem",
        }}
      >
        <h1
          className="text-4xl font-bold"
          style={{ textAlign: "center" }}
        >
          Digitale Vignette Österreich
        </h1>
      </Box>

      <Box className="container max-w-6xl mx-auto px-2">
        <Box
          id="fahrzeug"
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12"
 
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Schritt 1
          </Badge>
          <h2 className="text-2xl font-semibold text-center">
            Fahrzeug auswählen
          </h2>
          <Box
            className="w-full grid-cols-2 grid sm:grid-cols-2 gap-2 max-w-xl"
           >
            <div className="col-span-1 order-1">
              <label
                className={
                  selectedVehicle === "PKW"
                    ? "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-[#ff0083] text-white p-4 rounded-md"
                    : "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-slate-50 p-4 rounded-md"
                }
              >
                <input
                  type="radio"
                  name="vehicle"
                  value="PKW"
                  onChange={() => handleVehicleSelection("PKW")}
                />
                <div
                  className="wrapper--products mx-auto"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="vehicle-icon text-3xl">
                  <FontAwesomeIcon icon={faCar} />
                                    </span>
                  <span className="text-xl font-bold">PKW</span>
                </div>
              </label>
            </div>
            <div className="col-span-1 order-2">
              <label
                className={
                  selectedVehicle === "Motorrad"
                    ? "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-[#ff0083] text-white p-4 rounded-md"
                    : "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-slate-50 p-4 rounded-md"
                }
              >
                <input
                  type="radio"
                  name="vehicle"
                  value="Motorrad"
                  onChange={() => handleVehicleSelection("Motorrad")}
                />
                <div
                  className="wrapper--products mx-auto"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="vehicle-icon text-3xl">
                    <FontAwesomeIcon icon={faMotorcycle} />
                  </span>
                  <span className="text-xl font-bold">Motorrad</span>
                </div>
              </label>
            </div>
          </Box>
        </Box>

        <Box className=" -mt-4 -mb-4" style={{ textAlign: "center" }}>
          <img
            className="step-line"
            src={"/images/6106704c1072875c69f76536_line_middle-from-left.svg"}
          />
        </Box>

        <Box
          id="products"
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12"
           
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Schritt 2
          </Badge>

          <h2 className="text-2xl font-semibold mb-3" style={{ textAlign: "center" }}>
            Gewünschte Vignette wählen
            {selectedVehicle && (
              <>
                <span>&nbsp;für&nbsp;</span>
                <span className="p-primary">{selectedVehicle}</span>
              </>
            )}
          </h2>

          <Box className="not-prose w-full grid grid-cols-1 gap-6 md:text-lg md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
            {selectedVehicle &&
              products
                .filter((product) => product.vehicle === selectedVehicle)
                .map((product) => (
                  <div
                    className="relative cursor-pointer"
                    key={product.id}
 
                  >
                    <label
                      className={
                        selectedProduct === product
                          ? "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-[#ff0083] text-white p-4 rounded-md"
                          : "grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center border border-slate-200 hover:border-slate-300 hover:shadow-lg shadow-slate-100 bg-slate-50 p-4 rounded-md"
                      }
                    >
                      <input
                        type="radio"
                        name="product"
                        onChange={() => handleProductSelection(product)}
                      />
                      <div className="grid grid-cols-1 sm:flex justify-between w-full text-center md:text-left items-center">
                        <h4 className="text-xl mb-2 font-bold">
                          {product.name}
                        </h4>
                        <p className="h3 notranslate">
                          <strong>
                            {product.price.toLocaleString("de-DE", {
                              currency: "EUR",
                              style: "currency",
                            })}
                          </strong>
                        </p>
                        <span  
                        className={
                          selectedProduct === product
                            ? "btn bg-white text-[#ff0083] sm:order-last"
                            : "btn hover:bg-[#dc0073] bg-[#ff0083] text-white sm:order-last"
                        }
                        >
                          {selectedProduct === product
                            ? "Ausgewählt"
                            : "Vignette auswählen"}
                        </span>
                        <p
                          className={
                            selectedProduct === product
                              ? "text-white text-base font-normal"
                              : "text-slate-700 text-base font-normal"
                          }
                        >
                          <strong>{product.description}</strong>
                        </p>
                      </div>
                    </label>
                  </div>
                ))}
            {!selectedVehicle && (
              <div
                className="from-label text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  alignItems: "center",
                }}
              >
                Sie haben sich noch nicht für eine Fahrzeugart entschieden!
                <a href="#fahrzeug" className="mx-auto items-center text-sm justify-center gap-3 bg-[#ff0083] hover:bg-[#dc0073] text-white font-semibold py-2 px-2   rounded flex text-lg">
                  Wählen Sie Fahrzeugart 
                </a>
              </div>
            )}
          </Box>
        </Box>

        <Box className=" -mt-4 -mb-4" style={{ textAlign: "center" }}>
          <img
            className="step-line"
            src={"/images/6106704c83dcd656e34be5a3_line_right.svg"}
          />
        </Box>

        <Box
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12"
           
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Schritt 3
          </Badge>

          <h2 className="text-2xl font-semibold text-center">
            Wähle deinen Gültigkeitszeitraum
          </h2>

          {selectedProduct && selectedProduct.id !== 3 && (
            <label className="w-full max-w-md">
              <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2"> 
              Gültig ab:</p>
              <div className="notranslate">
                <DatePicker
                  selected={selectedDate}
                  className="form-control form-control-lg notranslate"
                  onChange={handleDateChange}
                  customInput={<ReadOnlyInput />}
                  minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                />
              </div>
            </label>
          )}

          {!selectedProduct && (
            <div
              className="from-label text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
              }}
            >
              Sie haben sich noch nicht für eine Vignette entschieden!
              <a href="#products" className="mx-auto items-center text-sm justify-center gap-3 bg-[#ff0083] hover:bg-[#dc0073] text-white font-semibold py-2 px-2   rounded flex text-lg">
                Wählen Sie Vignette 
              </a>{" "}
            </div>
          )}

          {selectedDate && selectedProduct && (
            <div className="flex-wrap items-end flex gap-1 bg-emerald-50 px-2 rounded text-sm">
              <span className="">Gültigkeit</span>
              <span className="text-md font-bold notranslate">
                {formatDate(selectedDate)}
              </span>
              <span className="">bis</span>
              <span className="text-md font-bold notranslate">
                {calculateValidityEndDate(selectedDate, selectedProduct)}
              </span>
              <span className="">23:59:59</span>
            </div>
          )}
        </Box>

        <Box className=" -mt-4 -mb-4" style={{ textAlign: "center" }}>
          <img
            className="step-line"
            src={"/images/6106704c1072875c69f76536_line_middle-from-left.svg"}
          />
        </Box>

        <Box
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12 input-py"
     
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Schritt 4
          </Badge>

          <h2 className="text-2xl font-semibold mb-3" style={{ textAlign: "center" }}>
            Kennzeichen fahrzeug
          </h2>

          <label
            className="w-full max-w-md"
          >
            <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
            Zulassungsstaat des Kennzeichens</p>
            <select
              className="form-select bg-slate-50 form-select-lg border rounded-3"
              style={{ paddingTop: "0", paddingBottom: "0" }}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="" disabled>
                Bitte auswählen
              </option>
              {CountryCode.map((country) => (
                <option
                  key={country.value}
                  value={country.value}
                  data-placeholdertext={country["data-placeholdertext"]}
                >
                  {country.label}
                </option>
              ))}
            </select>
          </label>
          <p className="no-margin text-center">
            <p className="my-2"> 
            <mark>
              {
                CountryCode.find((country) => country.value === countryCode)
                  ?.contentValue
              }
            </mark></p>
          </p>

          <Box className="wrap-kenz max-w-2xl">
            <label classname="w-full">
            <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
              Kennzeichen fahrzeug:</p>
              <div className="relative-div">
                <div
                  className={`country-code ${getCountryCodeColor(countryCode)}`}
                >
                  {
                    CountryCode.find((code) => code.value === countryCode)
                      ?.licenseValue
                  }
                </div>
                <input
                  className="border bg-slate-50 rounded-3 form-control form-control-lg uppercase"
                  type="text"
                  id="licensePlateInput"
                  value={licensePlate}
                  onChange={handleLicensePlateChange}
                  placeholder={
                    CountryCode.find(
                      (country) => country.value === countryCode
                    )?.["data-placeholdertext"]
                  }
                />
              </div>
            </label>
            <label classname="w-full">
            <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
              Kennzeichen fahrzeug wiederholen:
              </p>
              <div className="relative-div">
                <div
                  className={`country-code ${getCountryCodeColor(countryCode)}`}
                >
                  {
                    CountryCode.find((code) => code.value === countryCode)
                      ?.licenseValue
                  }
                </div>
                <input
                  className="border rounded-3 form-control bg-slate-50 form-control-lg uppercase"
                  type="text"
                  id="confirmLicensePlateInput"
                  value={confirmLicensePlate}
                  onChange={handleConfirmLicensePlateChange}
                  placeholder={
                    CountryCode.find(
                      (country) => country.value === countryCode
                    )?.["data-placeholdertext"]
                  }
                />
              </div>
            </label>
          </Box>
          {!licensePlateMatch && (
            <p style={{ color: "red" }}>Kennzeichen stimmen nicht überein.</p>
          )}
        </Box>

        <Box className=" -mt-4 -mb-4" style={{ textAlign: "center" }}>
          <img
            className="step-line"
            src={"/images/6106704c6dcf0ec5f5cb9acc_line_left.svg"}
          />
        </Box>

        <Box
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12 text"
 
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Schritt 5
          </Badge>

          <h2 className="text-2xl font-semibold mb-3" style={{ textAlign: "center" }}>
            Wohin dürfen wir den QR-Code und die Bestätigung senden?
          </h2>

          <Box className="wrap-kenz">
            <label classname="w-full">
              <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
              Vorname: </p>
              <input
                className="border bg-slate-50 rounded-3 form-control form-control-lg"
                type="text"
                placeholder="Vorname"
                id="nameInput"
              />
            </label>
            <label classname="w-full">
            <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
              Email:</p>
              <input
                className="border bg-slate-50 rounded-3 form-control form-control-lg"
                type="email"
                placeholder="Email"
                
                id="emailInput"
                value={email}
                onChange={handleEmailChange}
              />
            </label>
            <label classname="w-full">
            <p className="text-center font-bold mx-auto w-full text-base sm:text-base mb-2">
              Email bestätigen:
              </p>
              <input
                className="border rounded-3 bg-slate-50 form-control form-control-lg"
                type="email"
                placeholder="Email"
                
                id="confirmEmailInput"
                value={confirmEmail}
                onChange={handleConfirmEmailChange}
              />
            </label>
          </Box>
        </Box>

        <Box className=" -mt-4 -mb-4" style={{ textAlign: "center" }}>
          <img
            className="step-line"
            src={"/images/6106704c1072875c69f76536_line_middle-from-left.svg"}
          />
        </Box>

        <Box
          className="rounded-lg bg-white border relative mb-2 gap-2 mt-0 items-center flex flex-col mx-auto px-2 py-12 text"
 
        >
          <Badge
            style={{
              position: "absolute",
              left: "-15px",
              top: "-15px",
              background: "#ff0083 ",
              color: "rgb(255, 255, 255)",
              padding: "5px 15px",
              boxShadow: "#dc007359 0px 10px 40px -10px",
            }}
            className="rounded-3"
          >
            Letzter Schritt
          </Badge>

          <h2 className="text-2xl font-semibold mb-3" style={{ textAlign: "center" }}>
            Ausgewähltes Produkt
          </h2>
          <Box className="row g-2 gy-4" style={{ width: "100%" }}>
            <Box className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div>
                {selectedVehicle && (
                  <>
                    <p>
                      <strong>Fahrzeug:</strong>
                    </p>
                    <p className="Geldig-tekst">{selectedVehicle}</p>
                  </>
                )}

                {licensePlate && countryCode && (
                  <div>
                    {licensePlate && (
                      <>
                        <p>
                          <strong>Kennzeichen Fahrzeug:</strong>
                        </p>
                        <p className="Geldig-tekst uppercase notranslate ">
                          {licensePlate}
                        </p>
                      </>
                    )}
                    {CountryCode.find(
                      (country) => country.value === countryCode
                    )?.label && (
                      <>
                        <p>
                          <strong>Zulassungsstaat des Kennzeichens:</strong>
                        </p>
                        <p className="Geldig-tekst uppercase">
                          {
                            CountryCode.find(
                              (country) => country.value === countryCode
                            )?.label
                          }
                        </p>
                      </>
                    )}
                  </div>
                )}

                {selectedProductName && (
                  <>
                    <p>
                      <strong>Ausgewähltes Produkt:</strong>
                    </p>
                    <p className="Geldig-tekst">{selectedProductName}</p>
                  </>
                )}

                {!selectedProduct && (
                  <div
                    className="from-label text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      alignItems: "baseline",
                    }}
                  >
                    {selectedProduct && (
                      <p>
                        <strong>Gültigkeitszeitraum:</strong>
                      </p>
                    )}
                    <p>
                      Sie haben sich noch nicht für eine Vignette entschieden!
                    </p>
                    <a href="#products" className="p-primary">
                      Wählen Sie Vignette 
                    </a>
                  </div>
                )}

                {selectedDate && selectedProduct && (
                  <div
                    className="flex-wrap flex gap-1 my-3"
                    style={{ fontSize: "80%" }}
                  >
                    <span className="text-base">Gültigkeit</span>
                    <span className="text-base font-bold notranslate">
                      {formatDate(selectedDate)}
                    </span>
                    <span className="text-base">bis</span>
                    <span className="text-base font-bold notranslate">
                      {calculateValidityEndDate(selectedDate, selectedProduct)}
                    </span>
                    <span className="text-base">23:59:59</span>
                  </div>
                )}
              </div>
            </Box>

            <Box
              id="checkout--end"
              className="col-12 col-sm-12 col-md-12 col-lg-6 border bg-slate-50 text-center p-3 rounded-lg"
      
            >
              <div>
                <label className="lakezd">Gesamtbetrag:</label>

                <Box
                  className="display-5 w700 notranslate"
                  style={{ textAlign: "center" }}
                >
                  {totalPrice.toFixed(2)} €
                </Box>
                <label
                  className="text-muted Geldig-tekst"
                  style={{ marginBottom: "1rem", fontSize: "80%" }}
                >
                  inkl. Servicegebühr
                </label>
              </div>
              <span>
                <label className="flex items-center gap-2 text-sm mx-auto justify-center mb-4 font-bold">
                  <input
                    className="w-5 h-5"
                    type="checkbox"
                    id="shippingbox"
                    checked={expressShipping}
                    onChange={handleExpressShippingChange}
                  /> 
                  15-minuten verarbeitung (+4,95€)
                </label>
              </span>
              <button
                className="mx-auto items-center  w-full  justify-center gap-3 bg-emerald-500 hover:bg-emerald-400 text-2xl text-white font-bold py-3 px-2 border-b-4 border-emerald-700  rounded flex text-lg"
                onClick={handleFormSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Laden..." : "Jetzt bestellen"}{" "}
                <FontAwesomeIcon icon={faLock} />
              </button>

              <div
                aria-live="polite"
                aria-atomic="true"
                className="position-relative"
              >
                <div className="toast-container position-absolute top-0 end-0 p-3">
                  {formErrors.map((error, index) => (
                    <Toast
                      key={index}
                      show={showToast}
                      onClose={() => setShowToast(false)}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header
                        closeButton={true}
                        className="bg-danger text-white"
                      >
                        <Toast.Body style={{ textAlign: "left" }}>
                          {error}
                        </Toast.Body>
                      </Toast.Header>
                    </Toast>
                  ))}
                </div>
              </div>
              {inputError && (
                <p style={{ color: "red" }}>
                  Bitte füllen Sie alle Felder aus.
                </p>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export async function getServerSideProps() {
  return {
    props: {},
  };
}

export default ProductList;
