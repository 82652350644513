  import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

const Widerrufsrecht = () => {
  return (
    
    <div className="faq-page">
       <Helmet>
    
    <meta charset="utf-8" />
        <meta name="description" content="Widerrufsrecht für die Vermittlungsdienstleistung von digitalmaut.de" />
        <meta name="keywords" content="digitalmaut.de, Austrian vignette, Austrian toll sticker, Austria highway toll, Vignette Austria, Austrian road tax, Toll fee Austria, Autobahnvignette Österreich, Austrian motorway toll, Vignette for Austria, Widerrufsrecht, Vermittlungsdienstleistung, digitalmaut.de, Widerrufsfrist, Vertragsabschluss, Vertrag widerrufen, Europäisches Muster-Widerrufsformular, Zahlungen zurückerstatten, Widerrufsentscheidung" />
        <title>Widerrufsrecht - digitalmaut.de</title>
    <meta name="robots" content="index, follow" />
    <meta name="author" content="Digital Maut" />

   
       </Helmet>
      <Box className='container-lg py-5'>
        <div className="hero-banner">
          <h1>Widerrufsrecht</h1>
        </div>
<Box>
                  <h3>Widerrufsrecht für die Vermittlungsdienstleistung von digitalmaut.de</h3>
                  <p>Du hast das Recht, innerhalb einer Frist von 14 Tagen ohne Angabe von Gründen den Vertrag mit digitalmaut.de über den Vermittlungsdienst zu widerrufen. Diese Widerrufsfrist endet 14 Tage nach dem Tag, an dem du den Vermittlungsantrag bei uns gestellt hast, also dem Tag des Vertragsabschlusses.</p>

                  <p>Es ist jedoch wichtig zu beachten, dass das Widerrufsrecht vorzeitig erlischt, wenn du uns bei der Auftragserteilung gebeten hast, unverzüglich mit dem Vermittlungsdienst zu beginnen und wenn du der Zustimmung zugestimmt hast, dass dein Widerrufsrecht erlischt, sobald der Vermittlungsdienst vollständig erbracht ist. Dies ist der Fall, wenn der beabsichtigte Vertrag, auf den sich der Antrag bezieht, zustande gekommen ist.</p>

                  <p>Bitte beachte, dass das Widerrufsrecht nur innerhalb des angegebenen Zeitraums und unter den genannten Bedingungen gilt. Nach Ablauf der Widerrufsfrist oder wenn der Vermittlungsdienst vollständig gemäß Vereinbarung erbracht wurde, erlischt das Widerrufsrecht.</p>

                  <h3>Widerrufsrecht des Vertrags mit dem Anbieter der digitalen Vignette</h3>
                  <p>Du hast das Recht, innerhalb einer Frist von 14 Tagen ohne Angabe von Gründen den Vertrag, der über unseren Vermittlungsdienst mit dem Anbieter des digitalen Vignetts zustande gekommen ist, zu widerrufen. Diese Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsabschlusses, also dem Zeitpunkt, an dem wir im Namen von dir den Vertrag mit dem Anbieter geschlossen haben.</p>

                  <h3>Widerrufsrecht ausüben</h3>
                  <p>Um dein Widerrufsrecht auszuüben, musst du uns (digitalmaut.de, Jacob Schorerlaan 1, 2525 ZP Den Haag,Email: verkauf@digitalmaut.de Tel:070 225 0 558) mittels einer eindeutigen Erklärung (z.B. per Post, Fax oder E-Mail) über deinen Entschluss, den Vertrag zu widerrufen, informieren. Du kannst dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                      Zur Wahrung der Widerrufsfrist reicht es aus, dass du die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendest.
                      Bitte beachte, dass der Widerruf der über unsere Vermittlungsdienstleistung geschlossenen Vereinbarung ausschließlich den Vertrag mit dem Anbieter des digitalen Vignetts betrifft und nicht den Vermittlungsdienst selbst.</p>
<h3>Folgen des Widerrufsrechts</h3>
    <p>Wenn du dich dazu entscheidest, den Vertrag zu widerrufen, werden wir alle* Zahlungen, die du bis dahin geleistet hast, einschließlich eventueller Lieferkosten, unverzüglich und spätestens innerhalb von 14 Tagen nach Erhalt deiner Widerrufsentscheidung an dich zurückerstatten. Dies gilt mit Ausnahme von zusätzlichen Kosten, die aus deiner Wahl einer anderen Liefermethode als der von uns angebotenen günstigsten Standardlieferung resultieren. Wir werden den Betrag mit demselben Zahlungsmittel zurückerstatten, das du für die ursprüngliche Transaktion verwendet hast, es sei denn, du hast ausdrücklich einer anderen Rückzahlungsmethode zugestimmt. In jedem Fall werden für solche Rückerstattungen keine Kosten berechnet.</p>
<p>*Wenn du darum gebeten hast, dass die (Vermittlungs-)Dienstleistungen während der Widerrufsfrist begonnen werden, musst du einen Betrag zahlen, der im Verhältnis zu den bereits erbrachten Leistungen steht, die zum Zeitpunkt deiner Mitteilung über den Widerruf der Vereinbarung im Vergleich zur vollständigen Durchführung der Vereinbarung erbracht wurden.</p>
    

   <p> <strong><p>Vorzeitiges Erlöschen des Widerrufsrechts:</p>

   Das Widerrufsrecht erlischt bei einem Auftragsvertrag, wenn der Unternehmer den Dienst vollständig erbracht hat und erst mit der Durchführung des Dienstes begonnen hat, nachdem du als Verbraucher ausdrücklich zugestimmt und bestätigt hast, dass du darüber informiert bist, dass du das Recht verlierst, den Vertrag zu widerrufen, wenn der Unternehmer den Dienst vollständig erfüllt hat.</strong></p>
    <h3>Europäisches Muster-Widerrufsformular</h3>
    
    <p>Widerrufsformular</p>
<p>(Dieses Formular nur ausfüllen und zurücksenden, wenn Sie den Vertrag widerrufen möchten)</p>
<p>An [Name des Unternehmers]<br />
[Adresse des Unternehmers]<br />
[E-Mail-Adresse des Unternehmers, falls verfügbar]<br />
[Telefonnummer des Unternehmers, falls verfügbar]<br />
[Telefaxnummer des Unternehmers, falls verfügbar]</p>
<p>Ich/Wir (*) teile(n) Ihnen hiermit mit, dass ich/wir (*) unseren Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*) widerrufe(n)</p>
<p>Bestellt am (*)/Erhalten am (*)<br />
Name/Namen des Verbrauchers<br />
Adresse des Verbrauchers<br />
Unterschrift des Verbrauchers (nur bei schriftlicher Einreichung dieses Formulars)<br />
Datum</p>
<p>(*) Unzutreffendes bitte streichen.</p>
<p>Dies ist das standardisierte Musterformular, das zur Anzeige des Widerrufs einer Vereinbarung verwendet wird.</p>

    </Box> 

      </Box>
    </div>
  );
};

export async function getServerSideProps() {
  return {
    props: {}, 
  };
}

export default Widerrufsrecht;
