import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';



class PaymentForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    // Use the injected `stripe` object to create a token
    const { token } = await this.props.stripe.createToken();

    // Send the token to your server for processing
    // You can use fetch or your preferred method here
    // Example: fetch('/charge', { method: 'POST', body: { token: token.id }});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement />
        <button type="submit">Pay</button>
      </form>
    );
  }
}

export default injectStripe(PaymentForm);
