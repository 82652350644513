 
import { Box } from '@material-ui/core';
import React from 'react';
 
 
const ContactUs = () => {
  
  return (
    <div>
 

       
  <Box id="fahrzeug" className='rounded-3 bg-light border' style={{ width: '100%', textAlign: 'center', marginBottom: "0", marginTop: "0px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
     <h1 className='display-5' style={{ width: '100%', textAlign: 'center', zIndex: '1'}} >Contact</h1>
   </Box> 
  <Box id="fahrzeug" className='rounded-3 container-lg' style={{ width: '100%', maxWidth: '750px',  marginBottom: "0", marginTop: "0px", position: 'relative', marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <p>De Man Techniek<br/>
Jacob Schorerlaan 1 (keine Besucheradresse)<br/>
2525 ZP Den Haag<br/>
Niederlande</p>
<p>Wenn Sie Fragen zu einem Antrag haben oder einen neunen Antrag stellen möchten, dann Contactieren Sie uns bitte unter den unten angegebenen Contactdaten.</p>
<p>Wir möchten Sie darum bitten, uns per E-Mail zu Contactieren.</p>
 
<h2 id="Contact" class="scroll-mt-6">Contact</h2>
 
<p>Tel.+31 (0) 6 10 27 78 89</p>
<p>E-Mail: <a href="mailto:info@Digitalmaut.nl" target="_self">info@Digitalmaut.nl</a></p>
 

<p><em><span className='notranslate'> Digitalmaut.nl </span> ist ein  <mark>Vermittler</mark>  und beantragt in Ihrem Namen das gewünschte Österreich beim Herausgeber des Österreichts. voordiesen Service berechnet <span className='notranslate'> Digitalmaut.nl </span> Vermittlungsgebühren.</em></p>

    </Box> 
 

 
    </div>
  );
};
 

export default ContactUs;
