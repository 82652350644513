 
import { Box, Card } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

 
const AvgPage = () => {
  
  return (
    <div>
 <Helmet>
 
  <title>Terms and conditions | digitalmaut.de</title>
  <meta name="keywords" content="österreich vignette, vignette österreich kaufen, österreich vignette kaufen, österreich vignette digital, österreich vignette kosten, österreich vignette 2022, vignette österreich und slowenien, österreich vignette wo kaufen, vignette österreich 2022 preis, vignette österreich anhänger, österreich vignette motorrad, österreich vignette sofort gültig, österreich vignette wohnwagen, österreich vignette wo anbringen, österreich vignette 1 tag, österreich vignette anhänger"/>
  <meta name="description" content="Bei De Man Techniek legen wir großen Wert auf Ihre Privatsphäre. Wir sind vollständig bestrebt, die geltenden Gesetze und Vorschriften zum Datenschutz einzuhalten," />
  <meta name="robots" content="index,follow"/>
  <meta name="author" content="Digitalmaut"/>
  <meta name="publisher" content="De Man Techniek" />

  
  <meta http-equiv="X-UA-Compatible" content="ie=edge" />
</Helmet>

       
  <Box id="fahrzeug" className='rounded-3 bg-light border' style={{ width: '100%', textAlign: 'center', marginBottom: "0", marginTop: "0px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
     <h1 className='display-5' style={{ width: '100%', textAlign: 'center', zIndex: '1'}} >Terms and conditions</h1>
   </Box> 
  <Box id="fahrzeug" className='rounded-3 container-lg' style={{ width: '100%', maxWidth: '750px',  marginBottom: "0", marginTop: "0px", position: 'relative', marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <p>You can download and print the General Terms and Conditions <a href="/images/digitalmaut-de-terms-and-conditions.pdf" target='_blank'>here</a>.</p>
<p>1st July 2023</p>
<p><b>Intermediary and General Terms and Conditions - De Man Techniek</b></p>
<p>This document consists of two parts, namely Part A and Part B.</p>
<p><b>Part A - Intermediary Conditions</b></p>
<p>Part A contains the intermediary conditions of De Man Techniek (hereinafter referred to as "we," "us," or "our"). These conditions apply to our intermediary service, which we offer through our website (digitalmaut.de). If you have made an inquiry on our website to purchase a product and/or service from the selected supplier, we act on your behalf to obtain and deliver the respective product and/or service directly from the supplier.</p>
<p><b>Part B - General Terms and Conditions</b></p>
<p>Although we only act as an intermediary between you and the supplier and do not become a party to the contract concluded through our intermediary service, we ensure that you can exercise your legal rights against the supplier (whether through our intermediary service or otherwise). The specific conditions for this are listed in Part B below. It is important to note that the type of contract in which we have acted as an intermediary (delivery of a service, physical product, and/or digital content) determines the rights you have (at least). If you wish to rely on the conditions in Part B, such as the right of withdrawal, please contact us!</p>
<p><b>PART A - INTERMEDIARY CONDITIONS</b></p>
<p>Table of Contents:</p>
<p>Article 1 - Definitions</p>
<p>Article 2 - Characteristics of the Intermediary Service</p>
<p>Article 3 - Customer Satisfaction Guarantee</p>
<p><b>Article 1 - Definitions</b></p>
<p>The following definitions are used in the terms of Part A:</p>
<ul style={{ listStyle: 'decimal' }}>
    <li>
        <p>Website: the website digitalmaut.de, including all associated subdomains.</p>
    </li>
    <li>
        <p>We, us, or our: refers to De Man Techniek, a company located at Jacob Schorerlaan 1, (no visiting address), 2525 ZP The Hague, registered with the Chamber of Commerce under number 60532726.</p>
    </li>
</ul>
<p><b>Article 2 - Characteristics of the Intermediary Service</b></p>

<ul style={{ listStyle: 'decimal' }}>
    <li>
        <p>Through our website digitalmaut.de, you can submit a request for the intermediary purchase of the products and/or services listed on the website.</p>
    </li>
    <li>
        <p>We exclusively offer an intermediary service through our website. This means that by submitting your request through the website, you authorize us to acquire the desired offer from the respective supplier on your behalf. We may also accept the supplier's general terms and conditions on your behalf. Upon your request, we will provide you with these conditions.</p>
    </li>
    <li>
        <p>For the provision of the intermediary service, we charge a service fee as indicated on our website. These costs include value-added tax.</p>
    </li>
    <li>
        <p>The costs related to the desired agreement to which your request pertains are also indicated on the website. Unless otherwise stated and where applicable, these costs are inclusive of value-added tax and other incidental costs. You will pay these costs directly to us, after which we will forward them to the respective supplier. Thus, the payment for the products and/or services included in the mediated agreement is made through us, but we do not assume responsibility for the execution of this agreement by the supplier. However, we are responsible for paying the costs to the supplier on your behalf. Suppliers are not entitled to directly charge you costs, as all payments are processed through us unless expressly stated otherwise.</p>
    </li>
    <li>
        <p>The intermediary service is considered completed once the desired agreement between you and the supplier has been concluded. For more information on processing times, please refer to our website.</p>
    </li>
    <li>
        <p>The provisions in Part B apply to the intermediary service provided by us, to the extent relevant.</p>
    </li>
</ul>
<p><b>Article 3 - Customer Satisfaction Guarantee</b></p>
<ul style={{ listStyle: 'decimal' }}>
    <li>
        <p>If you are not satisfied with the performance of the (intermediary) agreement by us, you can notify us using the contact information provided on the website. We will handle each report or complaint carefully and as quickly as possible. You can expect to receive a substantive response from us within 14 calendar days.</p>
    </li>
    <li>
        <p>If you are not satisfied with the handling of your complaint, you (if you are a consumer) can resort to an arbitration board through the European Online Dispute Resolution platform (https://ec.europa.eu/consumers/odr/).</p>
    </li>
</ul>
<p><b>PART B - GENERAL TERMS AND CONDITIONS</b></p>
<p>Table of Contents:</p>
<p>Article 1 - Definitions</p>
<p>Article 2 - Identity of the trader</p>
<p>Article 3 - Scope of application</p>
<p>Article 4 - The offer</p>
<p>Article 5 - The contract</p>
<p>Article 6 - Right of withdrawal</p>
<p>Article 7 - Consumer's obligations during the cooling-off period</p>
<p>Article 8 - Exercise of the consumer's right of withdrawal and the associated costs</p>
<p>Article 9 - Trader's obligations in case of withdrawal</p>
<p>Article 10 - Exclusion of the right of withdrawal</p>
<p>Article 11 - The price</p>
<p>Article 12 - Performance and additional warranty</p>
<p>Article 13 - Delivery and execution</p>
<p>Article 14 - Continuing performance contracts: duration, termination, and extension</p>
<p>Article 15 - Payment</p>
<p>Article 16 - Complaints procedure</p>
<p>Article 17 - Disputes</p>
<p>Article 18 - Additional or deviating provisions</p>
<p><b>Article 1 - Definitions</b></p>
<p>In this document, the following terms are defined as follows:</p>
<ul style={{ listStyle: 'decimal' }}>
    <li>
        <p>Additional agreement: An agreement whereby the consumer acquires goods, digital content, and/or services in connection with a distance contract. These goods, digital content, and/or services are supplied by the trader or by a third party based on an agreement between that third party and the trader.</p>
    </li>
    <li><p>
    Cooling-off period: The period within which the consumer can exercise his right of withdrawal.
        </p></li>
        <li>
            <p>
            Consumer: a natural person who does not act for purposes relating to his trade, business, craft, or profession.
            </p>
        </li>
        <li>
            <p>
            Day: a calendar day.
            </p>
        </li>
        <li>
            <p>
            Digital content: data that is produced and supplied in digital form.
            </p>
        </li>
        <li>
            <p>
            Continuing performance contract: a contract that aims to provide for the regular supply of goods, services, and/or digital content over a certain period.
            </p>
        </li>
        <li>
            <p>Durable medium: any instrument, including email, that enables the consumer or trader to store personal information in a way that allows future consultation or use for a period that matches the purpose for which the information is intended and that allows unaltered reproduction of the stored information.</p>
        </li>
        <li>
            <p>Right of withdrawal: the consumer's right to withdraw from the distance contract within the cooling-off period.</p>
        </li>
        <li>
            <p>Trader: a natural or legal person who offers products, (access to) digital content, and/or services to consumers at a distance.</p>
        </li>
        <li>
            <p>Distance contract: a contract concluded between the trader and the consumer within the framework of an organized system for distance selling of goods, digital content, and/or services, whereby exclusive or additional use is made of one or more means of distance communication up to and including the conclusion of the contract.</p>
        </li>
        <li>
            <p>Model withdrawal form: the European model withdrawal form set out in Annex I to these terms and conditions. There is no obligation to provide Annex I if the consumer has no right of withdrawal in relation to his order.</p>
        </li>
        <li>
            <p>Means of distance communication: a means that can be used to conclude a contract without the consumer and trader being simultaneously present in the same physical space.</p>
        </li>
</ul>
<p><b>Article 2 - Identity of the trader</b></p>
<p>Regarding the brokerage service, refer to Article 1, Part A.</p>
<p>Regarding agreements concluded through mediation, we refer you to our website or contact De Man Techniek using the contact details provided on our website.</p>
<p><b>Article 3 - Scope of application</b></p>
<ul style={{ listStyle: 'decimal' }}>
    <li>
<p>  
These General Terms and Conditions apply to every offer made by the trader and to every distance contract concluded between the trader and the consumer.
    </p> 
</li>
    <li>
<p>  
Before the conclusion of the distance contract, the consumer will be provided with the text of these General Terms and Conditions. If this is not reasonably possible, the trader will indicate before the conclusion of the distance contract how the General Terms and Conditions can be inspected by the consumer and that they will be sent to the consumer free of charge upon request as soon as possible.
    </p> 
</li>
    <li>
<p>  
If the distance contract is concluded electronically, deviation from paragraph 2 is possible, and the text of these General Terms and Conditions can be made available to the consumer electronically before the conclusion of the distance contract, in a way that allows the consumer to store them on a durable medium. If this is not reasonably possible, it will be indicated before the conclusion of the distance contract where the consumer can electronically access the General Terms and Conditions and that they will be sent to the consumer electronically or in another manner free of charge upon request.
    </p> 
</li>
    <li>
<p>  
If specific product or service conditions also apply in addition to these General Terms and Conditions, paragraphs 2 and 3 of this article apply mutatis mutandis. In the event of conflicting conditions, the consumer may always invoke the provision that is most favorable to him.
    </p> 
</li>
    </ul>

    <b>Article 4 - The offer</b>

    <ul style={{ listStyle: 'decimal' }}>
<li>
<p>If an offer has a limited validity period or is subject to certain conditions, this will be explicitly stated in the offer.</p>
</li>
<li>
<p>The offer includes a complete and accurate description of the products, digital content, and/or services offered. The description is sufficiently detailed to allow the consumer to assess the offer properly. If the trader uses images, they must provide a true representation of the products, services, and/or digital content offered. Obvious errors or mistakes in the offer are not binding on the trader.</p>
</li>
<li>
<p>Each offer contains sufficient information for the consumer to understand what rights and obligations are associated with accepting the offer.</p>
</li>
    </ul>
<b>Article 5 - The contract</b>
<ul style={{ listStyle: 'decimal' }}>
<li>
<p>The contract is concluded when the consumer accepts the offer and fulfills the associated conditions, unless stated otherwise in paragraph 4.</p>
</li>
<li>
<p>If the consumer accepts the offer electronically, the trader shall immediately send an electronic confirmation to acknowledge receipt of the acceptance. As long as the receipt of this acceptance has not been confirmed by the trader, the consumer has the option to revoke the contract.</p>
</li>
<li>
<p>If the contract is concluded electronically, the trader shall take appropriate technical and organizational measures to secure the electronic transfer of data and ensure a secure web environment. If the consumer can pay electronically, the trader shall take appropriate security measures to ensure the electronic payment process is safe.</p>
</li>
<li>
<p>Within the limits of the law, the trader may ascertain whether the consumer can meet their payment obligations, as well as other relevant facts and circumstances that are necessary for a responsible conclusion of the distance contract. If, based on this examination, the trader has good reasons not to conclude the contract, they have the right to refuse an order or request, or to attach special conditions to the performance.</p>
</li>
<li>
<p>When delivering the product, service, or digital content, the trader shall provide the consumer with the following information in writing or in such a way that it can be stored on a durable medium and made accessible to the consumer:
</p>
<p>a. The physical address of the trader's place of business to which the consumer may address any complaints.</p>
<p>b. The conditions and procedures for exercising the right of withdrawal, or a clear statement if the right of withdrawal is excluded.</p>
<p>c. Information about existing after-sales services and guarantees.</p>
<p>d. The total price of the product, service, or digital content, including all taxes. If applicable, any additional delivery costs will also be mentioned. Furthermore, the payment method, delivery method, and the performance of the distance contract shall be described.</p>
<p>e. The conditions for terminating the contract if the contract has a duration of more than one year or is of indefinite duration.</p>
<p>f. If the consumer has a right of withdrawal, the model withdrawal form will be provided.</p>
</li>
<li>
<p>In the case of contracts with a duration of more than one year, the provisions in the above paragraph only apply to the first delivery.</p>
</li>
    </ul>
<p><b>Article 6 - Right of withdrawal</b></p>
<p>For products:</p>
<ul style={{ listStyle: 'decimal' }}>
<li>
<p>The consumer has the right to withdraw from a contract for the purchase of a product within a period of at least 14 days without giving any reason. The trader may ask the consumer for the reason for the withdrawal but cannot require them to provide a justification.</p>
</li>
<li>
<p>The withdrawal period referred to in paragraph 1 starts on the day after the consumer or a third party designated by the consumer, who is not the carrier, has received the product or:</p>
<p>a. If the consumer has ordered multiple products in the same order, and these are delivered separately, the withdrawal period starts on the day after the consumer or a third party designated by the consumer, who is not the carrier, has received the last product.</p>
<p>b. If the delivery of a product consists of multiple shipments or parts, the withdrawal period starts on the day after the consumer or a third party designated by the consumer, who is not the carrier, has received the last shipment or the last part.</p>
</li>
<li>
<p>For contracts relating to the regular delivery of products over a specified period, the withdrawal period starts on the day after the consumer or a third party designated by the consumer, who is not the carrier, has received the first product.</p>
<p>For services and digital content that is not supplied on a tangible medium, the withdrawal period starts on the day after the conclusion of the contract.</p>
</li>
<li>
<p>The consumer has the right to withdraw from a service contract and a contract for the supply of digital content that is not supplied on a tangible medium within a period of at least 14 days without giving any reason. The trader may ask the consumer for the reason for the withdrawal but cannot require them to provide a justification.</p>
</li>
<li>
<p>The withdrawal period referred to in paragraph 3 starts on the day after the contract is concluded.</p>
<p>As a consumer, you have a right to an extended withdrawal period for products, services, and digital content that is not supplied on a tangible medium if the trader has not properly informed you about your right of withdrawal.</p>
</li>
<li>
<p>If the trader has not provided the consumer with the necessary legal information about the right of withdrawal or the model withdrawal form, the withdrawal period ends twelve months after the end of the original withdrawal period determined in the preceding paragraphs.</p>
</li>
<li>
<p>If the trader provides the consumer with the information mentioned in the previous point within twelve months after the start of the original withdrawal period, the withdrawal period ends 14 days after the day on which the consumer received this information.</p>
</li>
</ul>
<b>Article 7 - Consumer's obligations during the withdrawal period:</b>

<ul style={{ listStyle: 'decimal' }}>
<li>The consumer is obliged to handle the product and packaging with care during the withdrawal period. The product may only be unpacked or used to the extent necessary to determine its nature, characteristics, and functioning. As a general principle, the consumer may handle and inspect the product in the same way as they would be allowed to do in a physical store.</li>
<li>The consumer is solely responsible for any depreciation of the product resulting from the use of the product beyond what is allowed in paragraph 1.</li>
<li>The consumer is not responsible for any depreciation of the product if the trader has not provided them with all the legally required information regarding the right of withdrawal before or at the time of the conclusion of the contract.</li>
</ul>
<b>Article 8 - Exercise of the consumer's right of withdrawal and associated costs:</b>

<ul style={{ listStyle: 'decimal' }}>
<li>If the consumer wishes to exercise their right of withdrawal, they must notify the trader of this within the withdrawal period. This can be done by using the model withdrawal form or in any other clear and unambiguous manner.</li>
<li>Within 14 days following the day of notification mentioned in paragraph 1, the consumer must return or hand over the product to the trader or their authorized representative as soon as possible. Unless the trader has offered to collect the product themselves, the consumer is obligated to do so. The consumer is considered to have returned the product on time if they send it back before the expiration of the withdrawal period.</li>
<li>The consumer must return the product in its original condition and packaging, to the extent reasonably possible, along with all supplied accessories. Furthermore, the consumer must follow the reasonable and clear instructions provided by the trader regarding the return.</li>
<li>The risk and burden of proof for the correct and timely exercise of the right of withdrawal lie with the consumer.</li>
<li>The direct costs of returning the product are borne by the consumer. If the trader has not indicated that the consumer is responsible for these costs or if the trader has stated that they will bear the costs themselves, the consumer is not obliged to pay the return costs.</li>
<li>If the consumer withdraws after having explicitly requested that the service or the supply of gas, water, or electricity, which are not supplied in a limited volume or quantity, begin during the withdrawal period, the consumer is obliged to pay the trader an amount. This amount is proportionate to the part of the obligation that the trader has fulfilled at the time of withdrawal in relation to the full fulfillment of the obligation.</li>
<li>If services have not been performed or if water, gas, electricity, or district heating are not supplied in a limited volume or quantity, or if the delivery of heat is concerned, no costs will be charged to the consumer.
    <ul>
        <li>a. If the trader has not provided the consumer with the legally required information about the right of withdrawal, reimbursement of costs in case of withdrawal, or the model withdrawal form, no costs will be charged to the consumer.</li>
        <li>b. If the consumer has not explicitly requested the performance of the service or the supply of gas, water, electricity, or district heating to begin during the withdrawal period, no costs will be charged to the consumer.</li>
    </ul>
</li>
<li>The consumer does not bear any costs for the full or partial delivery of digital content not supplied on a tangible medium if:
    <ul>
        <li>a. they have not given their express consent for the execution of the contract to begin before the expiration of the withdrawal period;</li>
        <li>b. they have not acknowledged that they lose their right of withdrawal by giving their consent; or</li>
        <li>c. if the trader has failed to confirm the consumer's statement, no costs will be charged for the full or partial delivery of digital content not supplied on a tangible medium.</li>
    </ul>
</li>
<li>If the consumer exercises their right of withdrawal, any additional agreements are automatically terminated.</li>
</ul>
<b>Article 9 - Trader's obligations in case of withdrawal:</b>

<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>If the trader offers the consumer the option to notify the withdrawal electronically, they will send an acknowledgment of receipt promptly upon receiving this notification.</p>
</li>
<li>
    <p>The trader will reimburse the consumer for all payments, including any delivery costs charged to the consumer for the returned product, without undue delay and in any case no later than 14 days from the day on which the consumer notified the withdrawal. Unless the trader offers to collect the product themselves, they may withhold the refund until they have received the product or until the consumer has provided proof of returning the product, whichever occurs earlier.</p>
</li>
<li>
    <p>The trader will use the same means of payment for the refund that the consumer used, unless the consumer agrees to a different method of payment. The refund is free of charge for the consumer.</p>
</li>
<li>
    <p>If the consumer chose a more expensive method of delivery than the cheapest standard delivery offered by the trader, the trader is not obliged to reimburse the additional costs for the more expensive method.</p>
</li>
</ul>
<b>Article 10 - Exclusion of the right of withdrawal:</b>

<p>The trader must explicitly state or at least exclude the following products and services from the right of withdrawal in the offer before the conclusion of the contract:</p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>Products or services whose price depends on fluctuations in the financial market that are beyond the trader's control and that may occur during the withdrawal period;</p>
</li>
<li>
    <p>Contracts concluded at a public auction. A public auction means a method of sale where the trader offers products, digital content, and/or services to consumers who are personally present or have the opportunity to participate personally in the auction, under the supervision of an auctioneer. In such cases, the successful bidder is obliged to acquire the products, digital content, and/or services;</p>
</li>
<li>
    <p>Service contracts after the service has been fully performed, but only if:</p>
    <p>a. the performance started with the consumer's explicit prior consent; and</p>
    <p>b. the consumer declared that they would lose their right of withdrawal once the trader has fully performed the contract;</p>
</li>
<li>
    <p>Package travel contracts within the meaning of Article 7:500 of the Dutch Civil Code (BW) and passenger transport contracts;</p>
</li>
<li>
    <p>Service contracts for the provision of accommodation, if the contract provides for a specific date or period of performance and these services are not for residential purposes, transport of goods, car rental, or catering;</p>
</li>
<li>
    <p>Contracts related to leisure activities, if the contract provides for a specific date or period of performance;</p>
</li>
<li>
    <p>Products that are made to the consumer's specifications, are not prefabricated, and are made based on an individual choice or decision of the consumer, or that are clearly intended for a specific person;</p>
</li>
<li>
    <p>Products that spoil quickly or have a limited shelf life;</p>
</li>
<li>
    <p>Sealed products that are not suitable for return due to health protection or hygiene reasons and whose sealing has been broken after delivery;</p>
</li>
<li>
    <p>Products that, by their nature, are irrevocably mixed with other products after delivery;</p>
</li>
<li>
    <p>Delivery of digital content that is not supplied on a tangible medium, but only if:</p>
    <p>a. the performance started with the consumer's explicit prior consent; and</p>
    <p>b. the consumer has declared that they thereby lose their right of withdrawal.</p>
</li>
</ul>
<b>Article 11 - The price:</b>


<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>During the validity period of the offer, the prices of the offered products and/or services remain unchanged unless there are changes in VAT rates.</p>
</li>
<li>
    <p>Contrary to the previous provisions, the trader may offer products or services whose prices are subject to fluctuations in the financial market and over which the trader has no control. In such cases, variable prices may be applied. The fluctuations and the fact that the indicated prices are indicative will be explicitly stated in the offer.</p>
</li>
<li>
    <p>Price increases within a period of 3 months after the contract is concluded are only allowed if they result from legal regulations or provisions.</p>
</li>
<li>
    <p>Price increases that occur after 3 months from the contract conclusion are only allowed if the trader has agreed to this and:</p>
    <p>a. it is based on legal regulations or provisions; or</p>
    <p>b. if the consumer has the right to terminate the contract from the day the price increase takes effect.</p>
</li>
<li>
    <p>The prices stated in the offer of products or services include VAT.</p>
</li>
</ul>
<b>Article 12 - Performance of the contract and additional warranty:</b>

<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>The trader guarantees that the products and/or services comply with the requirements of the contract, the specifications stated in the offer, the reasonable requirements of quality and usability, and the applicable legal provisions and government regulations at the time of contract conclusion. If agreed in advance, the trader also guarantees that the product is suitable for use in situations beyond normal use.</p>
</li>
<li>
    <p>Any additional warranty provided by the trader, supplier, manufacturer, or importer does not affect the consumer's legal rights and claims arising from the contract if the trader fails to fulfill their obligations under the contract.</p>
</li>
<li>
    <p>An additional warranty refers to any assurance provided by the trader, supplier, importer, or manufacturer that grants specific rights or claims to the consumer that go beyond the legal obligations in case of contract breaches.</p>
</li>
</ul>

<p><b>Article 13 - Delivery and Execution</b></p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    The trader shall exercise the utmost care when receiving product orders and executing them, as well as when assessing requests for services.
    </p>
</li>
<li>
    <p>
    The place of delivery is the address that the consumer has provided to the trader.
    </p>
</li>
<li>
    <p>
    In accordance with Article 4 of these General Terms and Conditions, the trader shall execute accepted orders as quickly as possible, but no later than within 30 days, unless a different delivery period has been agreed upon. In the event of any delays in delivery or if an order cannot be executed or completed in full, the consumer shall be informed within 30 days of placing the order. In such cases, the consumer has the right to terminate the contract free of charge and may be entitled to compensation.
    </p>
</li>
<li>
    <p>
    After termination in accordance with the previous provision, the trader shall promptly refund the amount paid by the consumer.
    </p>
</li>
<li>
    <p>
    The risk of damage to and/or loss of products shall remain with the trader until the moment of delivery to the consumer or a pre-designated representative known to the trader, unless expressly agreed otherwise.
    </p>
</li>
</ul>
<p><b>Article 14 - Continuous Performance Contracts: Duration, Termination, and Extension</b></p>
<p>Termination:</p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    The consumer shall have the right to terminate an indefinite duration contract for the regular supply of products (including electricity) or services at any time, subject to the agreed termination rules and a notice period of no more than one month.
    </p>
</li>
<li>
    <p>
    The consumer shall have the right to terminate a fixed-term contract for the regular supply of products (including electricity) or services at any time upon expiration of the agreed duration, subject to the agreed termination rules and a notice period of no more than one month.
    </p>
</li>
<li>
    <p>
    The consumer shall have the option to terminate the above-mentioned contracts in accordance with the preceding provisions in the following manner:
    </p>
    <p>
    - Terminate at any time without being restricted to a specific date or period;
    </p>
    <p>
    - Terminate in the same manner as the consumer entered into the contract;
    </p>
    <p>
    - Terminate with the same notice period set by the trader for themselves.
    </p>
</li>
</ul>
<p>Extension:</p>



<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    A fixed-term contract relating to the regular supply of products (including electricity) or services shall not be automatically extended or renewed for a specified duration.
    </p>
</li>
<li>
    <p>
    Notwithstanding the previous provision, a fixed-term contract relating to the regular supply of daily, news, and weekly newspapers as well as magazines may be tacitly extended for a specified duration of up to three months. However, the consumer has the right to terminate this extended contract at the end of the extension period with a notice period of no more than one month.
    </p>
</li>
<li>
    <p>
    A fixed-term contract relating to the regular supply of products or services may only be tacitly extended for an indefinite duration if the consumer has the right to terminate it at any time with a notice period of no more than one month. If the contract concerns the regular supply of daily, news, and weekly newspapers as well as magazines with a frequency of less than once a month, a maximum notice period of three months applies.
    </p>
</li>
<li>
    <p>
    A trial or introductory subscription for the regular supply of daily, news, and weekly newspapers as well as magazines with a limited duration shall not be automatically extended and shall end automatically upon expiration of the trial or introductory period.
    </p>
</li>
</ul>
<p>Duration:</p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    If a contract has a duration of more than one year, the consumer has the right to terminate the contract at any time after one year with a notice period of no more than one month, unless considerations of reasonableness and fairness preclude termination before the agreed duration ends.
    </p>
</li>
</ul>
<p><b>Article 15 - Payment</b></p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    Unless otherwise agreed in the contract or additional conditions, payments owed by the consumer must be made within 14 days from the start of the withdrawal period or, if there is no withdrawal period, within 14 days from the conclusion of the contract. In the case of a service agreement, this period starts on the day after the consumer received the confirmation of the agreement.
    </p>
    </li>
<li>
    <p>
    In the sale of products to consumers, it is not permissible to require the consumer to make an advance payment exceeding 50% of the total amount in the General Terms and Conditions. If an advance payment is agreed upon, the consumer cannot exercise any rights regarding the performance of the corresponding order or service unless the agreed advance payment has been made.
    </p>
    </li>
<li>
    <p>
    It is the responsibility of the consumer to immediately notify the trader of any inaccuracies in the provided or stated payment details.
    </p>
    </li>
<li>
    <p>
    If the consumer fails to fulfill their payment obligations on time, they shall be liable to pay statutory interest on the outstanding amount after the trader has reminded them of the late payment and granted a period of 14 days for payment. Additionally, the trader shall have the right to charge extrajudicial collection costs. These collection costs are determined as follows: 15% on outstanding amounts up to €2,500; 10% on the next €2,500; and 5% on the subsequent €5,000, with a minimum of €40. The trader is entitled to deviate from these amounts and percentages to the benefit of the consumer.
    </p>
    </li>
</ul>
<p><b>Article 16 - Complaints Procedure</b></p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    The trader has a well-publicized complaints procedure and will handle the complaint in accordance with this procedure.
    </p>
</li>
<li>
    <p>
    Complaints about the performance of the contract must be submitted to the trader in full and clearly within a reasonable time after the consumer has discovered the defects.
    </p>
</li>
<li>
    <p>
    Complaints submitted to the trader will be answered within 14 days from the date of receipt. If a complaint requires a longer processing time, the trader will send an acknowledgment of receipt within this period of 14 days and provide an indication of when the consumer can expect a more detailed response.
    </p>
</li>
<li>
    <p>
    The consumer must allow the trader at least 4 weeks to resolve the complaint through mutual consultation. If no solution has been reached within this period, a dispute arises that can be submitted to dispute resolution.
    </p>
</li>
</ul>
<p><b>Article 17 - Disputes</b></p>
<ul style={{ listStyle: 'decimal' }}>
<li>
    <p>
    Dutch law exclusively applies to agreements between the trader and the consumer to which these General Terms and Conditions apply.
    </p>
</li>
</ul>

<p><b>Article 18 - Supplementary or Deviating Provisions</b></p>
<p>Supplementary provisions or deviations from these General Terms and Conditions must not be to the detriment of the consumer and must be recorded in writing or in such a way that allows the consumer to store them on a durable medium.</p>
<p><b>Sample Withdrawal Form</b></p>
<p>(Complete and return this form only if you wish to withdraw from the contract)</p>
<p><b>To:</b></p>
<p>[Name of the trader]</p>
<p>[Geographical address of the trader]</p>
<p>[Fax number of the trader, if available]</p>
<p>[Email address or electronic address of the trader]</p>
<p>I/We* hereby give notice that I/we* withdraw from my/our* contract of</p>
<ul>
    <li>the sale of the following products: [Description of the product]*</li>
    <li>the supply of the following digital content: [Description of the digital content]*</li>
    <li>the provision of the following service: [Description of the service]*</li>
</ul>
<p>Ordered on*/received on* [Date of the order for services or receipt for products]</p>
<p>[Name of the consumer(s)]</p>
<p>[Address of the consumer(s)]</p>
<p>[Signature of the consumer(s)] (only if this form is submitted on paper)</p>







   </Box> 
 

 
    </div>
  );
};
 

export default AvgPage;
