import React from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronCircleUp, faMailBulk } from '@fortawesome/free-solid-svg-icons';
 
function Footer2() {
  return (
    <div style={{ background: '#282828', color: '#ffffff' }}>
        <div className="row" style={{ marginRight: '0', marginLeft: '0' }}>
        <Box className='bg-light' style={{ marginBottom: '2rem', marginTop: '0', position: 'relative', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="container">

        <ul className="branding-header-list-items h6">
          <li className="branding-header-list-item"><strong>Schnell geliefert</strong></li>
          <li className="branding-header-list-item"><strong>Sofort verfügbar</strong></li>
          <li className="branding-header-list-item"><strong>Aktiv innerhalb von 2 Stunden</strong></li>
        </ul>          
        </div>
 
        </Box>
          </div>
      <div className="container" style={{ paddingTop: '2rem', position: 'relative' }}>
        <div className="row g-4">
          <div className="col-lg-3">
            <div className="row">
              <div className="col-10">
              <a className="navbar-brand h5" href="/" style={{ color: '#fff', padding: '0' }}>
            <img
              src="/images/DM.svg"
              alt=""
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
           </a>
          
          <ul className="list-unstyled">
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><FontAwesomeIcon icon={faMailBulk}/>
              <a href="mailto:info@digitalmaut.de" className="text-muted" style={{ margin: '0' }}>info@digitalmaut.de</a></li>
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> 
              <a href="#" className="text-muted" style={{ margin: '0' }}>HrNr: 60532725</a></li>
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> 
              <a href="#" className="text-muted" style={{ margin: '0' }}>USt-IdNr: NL001537422B07</a></li>
 
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> 
              <a href="#" className="text-muted" style={{ margin: '0' }}>Experten für Vignette</a></li>
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> 
              <a href="#" className="text-muted" style={{ margin: '0' }}>Sofort verfügbar</a></li>
              <li style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> 
              <a href="#" className="text-muted" style={{ margin: '0' }}>Schnell geliefert</a></li>
              </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <h5>Digitale vignette</h5>
            <ul className="list-unstyled">
              <li><a href="/vignette-oesterreich?selectedVehicle=PKW" className="text-muted">10-Tages-Vignette Österreich</a></li>
              <li><a href="/vignette-oesterreich?selectedVehicle=PKW" className="text-muted">2-Monats-Vignette Österreich</a></li>
              <li><a href="/vignette-oesterreich?selectedVehicle=PKW" className="text-muted">Jahres-Vignette Österreich 2023</a></li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h5>Digitale streckenmaut</h5>
            <ul className="list-unstyled">
              <li><a href="/streckenmaut?selectedVehicle=A%2013%20Brenner%20Autobahn" className="text-muted">A 13 Brenner Autobahn</a></li>
              <li><a href="/streckenmaut?selectedVehicle=A%2010%20Tauern%20Autobahn" className="text-muted">A 10 Tauern Autobahn</a></li>
              <li><a href="/streckenmaut?selectedVehicle=A%2011%20Karawanken%20Autobahn%20-%20Fahrtrichtung%20S%C3%BCden" className="text-muted">A 11 Karawanken Autobahn - Fahrtrichtung Süden</a></li>
              <li><a href="/streckenmaut?selectedVehicle=A%209%20Pyhrn%20Autobahn" className="text-muted">A 9 Pyhrn Autobahn</a></li>
            </ul>
          </div>
          <div className="col-lg-3">
             <a href="/vignette-oesterreich" className="btn btn-primary btn-lg">Vignette kaufen</a>
          </div>
          <Box style={{ paddingTop: '5rem', paddingBottom: '3rem', marginTop: '0', position: 'relative', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
        <p className="text-muted"><em>digitalmaut.de ist ein <mark>Vermittler</mark> und beantragt in Ihrem Namen das gewünschte Vignette beim Herausgeber des Vignetts. Für diesen Service berechnet digitalmaut.de Vermittlungsgebühren.</em></p>
      </Box>
        </div>
        <Box style={{ paddingBottom: '5rem' }}>
        <div className="row g-4">

        <div className="col-lg-3">
             <ul className="list-unstyled">
              <li><a href="/Widerrufsrecht" className="text-muted"> Widerrufsrecht</a></li>
              <li><a href="/Bestellen" className="text-muted"> Bestellen, bezahlen und liefern</a></li>
              <li><a href="/privacy-policy" className="text-muted"> Privacy policy</a></li>
              <li><a href="/terms-and-conditions" className="text-muted"> Terms and conditions</a></li>
              <li> <a href="/faq" className="text-muted"> FAQ</a></li>
              <li style={{ position: 'absolute', right: '0' }}><a href="#" className="text-muted"><FontAwesomeIcon icon={faChevronCircleUp}/></a></li>
            </ul>
 
          </div> 

        </div>
        </Box>
      </div>

    </div>
  );
}

export default Footer2;
