import { faMap, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const ImageModal = ({ imageUrl, altText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className='modal' style={{ marginTop: '1rem' }}>
        <button className='h6 border p-2 rounded-3' onClick={openModal}>
          <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faInfoCircle} /> Streckenmautkarte anzeigen
        </button>
        {isOpen && (
          <div className='modal-content'>
            <span className='close-button p-2 border bg-light rounded-3' onClick={closeModal}>&times;</span>
            <img src={'../images/steckenmaut.jpg'} alt={altText} className='modal-image' />
          </div>
        )}
      </div>

      <style>
        {`
          .modal {
            position: relative;
            display: inline-block;
          }

          .modal-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .modal-image {
            max-width: 100%;
            max-height: 90vh;
          }

          .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 24px;
             cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

export default ImageModal;
